import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { getAuthToken, BASEURI } from '../utils';
import loading from '../loading.svg';
import EventItem from './EventItem';
import FilterOptions from './FilterOptions';


export default function IrrigationEvents(props) {
    const intl = useIntl();
    const [logs, setLogs] = useState([]);

    const [filterVars, setFilterVars] = useState({
        device: {},
        sector: {},
        error: true,
        start: true,
        stop: true,
    })
    const [range, setRange] = useState({ end: new Date(), start: new Date(new Date().setDate(new Date().getDate() - 25)) });

    const [loadingLogs, setLoadingLogs] = useState(false);

    const [loadingNextPage, setLoadingNextPage] = useState(false);
    const [loadingPrevPage, setLoadingPrevPage] = useState(false);

    const [nextPage, setNextPage] = useState(null);
    const [prevPage, setPrevPage] = useState(null);
    const [intervalId, setIntervalId] = useState(null);

    const [showFilter, setShowFilter] = useState(false);

    const irrigationStatus = {
        'active': intl.formatMessage({ id: 'app.scheduler.events.irrigating', defaultMessage: 'Irrigating' }),
        'completed': intl.formatMessage({ id: 'app.scheduler.events.irrigationFinished', defaultMessage: 'Irrigation Finished' }),
        'error': intl.formatMessage({ id: 'app.scheduler.events.error', defaultMessage: 'Error' }),
    }

    const bgColor = {
        'active': 'border-r-blue-500 bg-blue-500',
        'completed': 'border-r-green-500 bg-green-500',
        'error': 'border-r-red-500 bg-red-500'
    }

    useEffect(() => {
        filter();
    }, [filterVars, range])


    useEffect(() => {
        if (!intervalId)
            setIntervalId(setInterval(() => {


                if (filterVars.device.serial) {
                    filter()
                }
                else {
                    loadEvents()
                }
            }, 1000 * 30)
            )


    }, [intervalId])

    function filter() {
        setLoadingLogs(true);

        const variables = {
            start: filterVars.start ? 'active' : '',
            end: filterVars.stop ? 'completed' : '',
            error: filterVars.error ? 'error' : '',
            device: filterVars.device.serial ? filterVars.device.serial : '',
            sector: filterVars.sector.id ? filterVars.sector.id : '',
            start_date: range.start ? range.start.toJSON() : '',
            end_date: range.end ? range.end.toJSON() : '',
        }

        let url = BASEURI + `/api/scheduler/irrigation-event/filter/?` + new URLSearchParams(variables),
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        if (response.status !== 200 && response.status !== 201)
                            return response.text().then(text => { throw new Error(text) });
                        return response.json();
                    })

                    .then((data) => {
                        setLoadingLogs(false);
                        setLogs(data.results);
                        setNextPage(data.next);
                        setPrevPage(data.previous);
                        setLoadingNextPage(false);
                        setLoadingPrevPage(false);

                    })

                    .catch(error => {
                        console.error('Error:', error.code);
                        setLoadingLogs(false);
                        setLoadingNextPage(false);
                        setLoadingPrevPage(false);
                    });
            });
    }


    const loadEvents = (url = `${BASEURI}/api/scheduler/irrigation-event/`) => {
        setLoadingLogs(true);
        let options = {
            method: 'GET',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        if (response.status !== 200 && response.status !== 201)
                            return response.text().then(text => { throw new Error(text) });
                        return response.json();
                    })

                    .then((data) => {
                        setLoadingLogs(false);
                        setLogs(data.results);
                        setNextPage(data.next);
                        setPrevPage(data.previous);
                        setLoadingNextPage(false);
                        setLoadingPrevPage(false);

                    })

                    .catch(error => {
                        console.error('Error:', error.code);
                        setLoadingLogs(false);
                        setLoadingNextPage(false);
                        setLoadingPrevPage(false);
                    });
            });
    }

    useEffect(() => {
        loadEvents();
    }, [])

    function handleNextPage() {
        if (!nextPage)
            return

        clearInterval(intervalId);
        setLoadingNextPage(true);
        loadEvents(nextPage);
    }

    function handlePreviousPage() {
        if (!prevPage)
            return
        setLoadingPrevPage(true);
        loadEvents(prevPage);
    }

    function handleDateChange(e) {
        setRange(e.value);

        clearInterval(intervalId);
        setIntervalId(null);
    }

    function handleChange(e) {

        const name = e.target.name;
        const value = e.target.value;

        if (name === 'device') {
            setFilterVars({ ...filterVars, sector: {}, [name]: value });
            props.setStation(value.serial);
        } else {
            setFilterVars({ ...filterVars, [name]: value })
        }

        clearInterval(intervalId);
        setIntervalId(null);
    }


    return <>

        <div className={`mr-3 ml-3 mt-1 rounded-2xl border-0 border-white bg-white ${showFilter && 'border-b-2 border-b-green-500'}`}>

            <div className="inline-flex justify-between items-center w-full">
                <span className='m-1 pr-4 border-r-2 border-r-slate-50'>
                    <i className="material-icons pr-4 pl-4 p-2 m-2 ml-4 rounded-xl text-green-500 bg-slate-50" onClick={() => { setShowFilter(!showFilter) }}>filter_alt</i>
                </span>
                <button
                    className="p-2 text-xl text-gray-500 font-semibold"
                    onClick={() => { setShowFilter(!showFilter) }}>
                    {intl.formatMessage({ id: 'app.scheduler.events.filterby', defaultMessage: 'Filter By' })}
                </button>
                <button className="p-2 text-green-500 p-4" onClick={() => { setShowFilter(!showFilter) }}>
                    {showFilter ? <i className="material-icons">keyboard_arrow_up</i> :
                        <i className="material-icons">keyboard_arrow_down</i>}
                </button>
            </div>

            {showFilter &&
                <FilterOptions
                    stations={props.stations}
                    filterVars={filterVars}
                    handleDateChange={handleDateChange}
                    handleChange={handleChange}
                    sectors={props.sectors}
                    handleStationFilterChange={props.handleStationFilterChange}
                    range={range}
                />
            }
        </div>

        <div className="m-3 rounded-2xl border-0 border-white bg-white pt-4 pb-5 pr-5">
            <table className="w-full">
                <tbody>
                    <tr>
                        <td className="bg-white p-0">
                            <div className="relative h-full">
                                <div className="ml-6 border-b border-b-gray-100 pb-4">
                                    <div className="mt-4 text-lg font-medium">{intl.formatMessage({ id: 'app.scheduler.events.irrigationEvents', defaultMessage: 'Irrigation Events' })}</div>
                                </div>
                            </div>
                        </td>
                    </tr>

                    {
                        logs.map((item) => {
                            const status = irrigationStatus[item.status]
                            const color = bgColor[item.status]

                            return <EventItem
                                key={item.id}
                                status={status}
                                color={color}
                                deviceName={item?.device?.name}
                                sectorName={item?.sector?.name}
                                created={item.created}
                            />
                        })
                    }

                    <tr>
                        <td className="bg-white p-0">
                            <div className="relative h-full">
                                <div className="flex justify-between ml-6 border-b border-b-gray-100 pb-4 pt-4 ">
                                    <button onClick={handlePreviousPage} class={`hover:bg-slate-300 text-green-500 font-bold p-4  bg-slate-50 rounded-xl ${!prevPage && 'cursor-not-allowed'}`}>
                                        {
                                            loadingPrevPage ?
                                                <img style={{ width: '20px' }} src={loading} alt='loading' /> :
                                                <i className="material-icons" >arrow_back_ios</i>
                                        }
                                    </button>
                                    <button onClick={handleNextPage} class={`hover:bg-slate-300 text-green-500 font-bold p-4 bg-slate-50 rounded-xl ${!nextPage && 'cursor-not-allowed'}`}>
                                        {
                                            loadingNextPage ?
                                                <img style={{ width: '20px' }} src={loading} alt='loading' /> :
                                                <i className="material-icons" >arrow_forward_ios</i>
                                        }
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </>
}