import React, { Component } from 'react';
import { getAuthToken, formatDate, BASEURI } from '../utils';
import Modal from '../general/Modal';
import StatMenu from './StatMenu';
import StatModalForm from './StatDialog';



class StatItem extends Component {
	constructor(props) {
		super(props);
		this.handleStatClick = this.handleStatClick.bind(this);
	}

	handleStatClick() {
		this.props.onClick(this.props.statId, this.props.station);
	}
	render() {


		return (
			<tr
				onClick={this.handleStatClick}
				className="flex-wrap border-b border-b-emerald-600 p-2 first:rounded-t-md last:rounded-b-md last:border-0"
			>
				<td className="p-2 pl-3">
					<div
						className="flex flex-col justify-center text-white font-bold"
						style={{ minHeight: 46.5 }}
					>
						<span> {this.props.data.title} </span>
					</div>
				</td>

				<td className="p-2">
					<tr className='flex justify-between gap-4 text-center'>
						<td>
							<div className="text-lg text-white ">
								{this.props.intl.formatNumber(this.props.data.value)}
							</div>
						</td>
						<td>
							<div className=" font-light text-white ">
								{this.props.intl.formatDate(this.props.data.max_date, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
							</div>
						</td>
					</tr>
					{
						this.props.data.last_year &&
						<tr className='flex justify-between gap-4 text-center'>
							<td>
								<div className="text-sm font-medium text-emerald-400">
									{this.props.intl.formatNumber(this.props.data.last_year_value)}
								</div>
							</td>
							<td>
								<div className="text-sm text-emerald-400">
									{this.props.intl.formatDate(this.props.data.last_year_max_date, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
								</div>
							</td>
						</tr>
					}
				</td>
			</tr>
		);
	}
}



class Stat extends Component {
	constructor(props) {
		super(props);

		this.state = {
			stats: [],
			editing: false,
			action: 'new',
			current: null,
			extracts: [],
			//default date range on StatDialog
			daterange: { start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date() },
			loading: false,
			graphs: [],
			message: null
		}

		this.editStat = this.editStat.bind(this);
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.createStat = this.createStat.bind(this);
		this.deleteStat = this.deleteStat.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.updateStat = this.updateStat.bind(this);
		this.initNewStat = this.initNewStat.bind(this);
		this.loadGraphsList = this.loadGraphsList.bind(this);
		this.handleGraphFilterChange = this.handleGraphFilterChange.bind(this);
		this.renameWidget = this.renameWidget.bind(this);
		this.getMarker = this.getMarker.bind(this);
	}

	handleGraphFilterChange(event) {

		this.setState(() => {
			return {
				graphs: this.graphsListSource.slice().filter(item => {
					let filterText = event.filter.value,
						itemText = `${item.label.toLowerCase()} ${item.chart.name.toLowerCase()} [${item.calculation.toLowerCase()}]`;

					for (let i in filterText.split(' ')) {
						let textSplit = filterText.split(' ')[i];
						// if (item.name.toLowerCase().indexOf(textSplit) !== -1 || item.device.name.toLowerCase().indexOf(textSplit) !== -1 || item.port.indexOf(textSplit) !== -1)
						if (itemText.indexOf(textSplit) !== -1)
							return true
						else
							return false
					}
					return true;
				})
			};
		});
	}

	editStat(statId) {

		// let current, stats = this.state.stats.slice();

		//find the stat 
		// for (let i in stats){

		// 	if (stats[i].id === statId){
		// 		current = stats[i];
		// 		break;
		// 	}
		// }

		this.setState({ editing: true, action: 'loading' });

		let url = `${BASEURI}/api/stats/get/${statId}/`,
			options = {
				method: 'GET',
				headers: {
					"Content-Type": "application/json; charset=utf-8",
				}
			};

		getAuthToken()
			.then(token => token)
			.catch(token => token)
			.then(token => {

				options.headers.Authorization = `Bearer  ${token}`;

				fetch(url, options)
					.then((response) => {
						return response.json();
					})
					.then((current) => {

						let daterange = {
							start: new Date(current.min_date),
							end: new Date(current.max_date)
						}

						if (this.state.graphs.length === 0)
							this.loadGraphsList();

						if (current.calculation === 'Forecast') {
							let markerObject = this.getMarker(current.location_marker);
							current.location_marker = markerObject;
						}
						if (['Raw', 'Custom Formula'].indexOf(current.calculation) !== -1)
							this.props.handleStationChange({}, current.sensors[0].device)

						this.setState({
							// editing: true,
							action: 'edit',
							current: current,
							daterange: daterange
						});

					}).catch(error => console.error('Error:', error));

			});
	}

	loadGraphsList() {

		let url = `${BASEURI}/api/allgraphs/`,
			options = {
				method: 'GET',
				headers: {
					"Content-Type": "application/json; charset=utf-8",
				}
			};

		getAuthToken()
			.then(token => token)
			.catch(token => token)
			.then(token => {

				options.headers.Authorization = `Bearer  ${token}`;

				fetch(url, options)
					.then((response) => {
						return response.json();
					})
					.then((graphsList) => {
						if (graphsList.length > 0)
							this.setState({
								graphs: graphsList,
							});
						this.graphsListSource = graphsList;
					})
					.catch(error => console.error('Error:', error));

			});

	}

	//set marker object as as value
	getMarker(id) {
		for (let i in this.props.markers) {
			if (this.props.markers[i].id === id) {
				return this.props.markers[i];
			};
		};
	}

	componentDidMount() {
		this.setState({
			stats: this.props.widget.stats.slice(),
			sensors: this.props.sensors ? this.props.sensors.slice() : []
		});
	}


	deleteStat(stat) {

		// Get delete confirmation.
		if (!window.confirm(
			this.props.intl.formatMessage({ id: 'app.stat.deleteMessage', defaultMessage: 'Are you sure you want to delete stat?' })
		))
			return

		this.setState({ loading: true, action: 'delete' });

		let url = `${BASEURI}/api/stats/rud/${stat}/`,
			options = {
				method: 'DELETE',
				headers: {
					"Content-Type": "application/json; charset=utf-8"
				}
			}

		getAuthToken()
			.then(token => token)
			.catch(token => token)
			.then(token => {

				options.headers.Authorization = `Bearer  ${token}`;

				fetch(url, options)
					.then(response => {
						let statList = this.state.stats.slice();
						let statToDelete = null;
						for (let i = 0; i < statList.length; i++) {
							if (statList[i].id === stat) {
								statToDelete = i;
								break;
							}
						}

						statList.splice(statToDelete, 1);

						this.setState({ stats: statList, editing: false, loading: false });
					});

			});
	}

	openModal() {
		this.setState({
			editing: true
		});
	}

	closeModal() {
		this.setState({
			editing: false
		});
	}

	createStat() {

		this.setState({ loading: true });

		let newStat = Object.assign({}, this.state.current);
		if (newStat.calculation === 'From Graph') {
			newStat.graph = newStat.graph.id;
			newStat.widget = this.props.statWidgetId;
		} else if (newStat.calculation === 'Forecast') {
			newStat.widget = this.props.statWidgetId;
			newStat.location_marker = newStat.location_marker.id;
		} else {

			if (newStat.sensors === undefined || newStat.sensors.length == 0) {
				this.setState({
					loading: false,
					message: this.props.intl.formatMessage({ id: 'app.stat.sensorSelectMessage', defaultMessage: 'Please select at least one sensor!' })
				});
				return
			}

			newStat.widget = this.props.statWidgetId;
			newStat.extract = newStat.extract.id;


			let sensors = [];
			for (let i in newStat.sensors)
				sensors.push(newStat.sensors[i].id);

			newStat.sensors = sensors;
		}

		let url = `${BASEURI}/api/stats/create/${this.props.statWidgetId}/`,
			options = {
				method: 'POST',
				body: JSON.stringify(newStat),
				headers: {
					"Content-Type": "application/json; charset=utf-8",
				}
			};

		getAuthToken()
			.then(token => token)
			.catch(token => token)
			.then(token => {

				options.headers.Authorization = `Bearer  ${token}`;

				fetch(url, options)
					.then((response) => response.json())
					.then(stat => {
						let stats = this.state.stats.slice();
						stat.sensors = this.state.current.sensors;
						stat.extract = this.state.current.extract;
						stats.push(stat);
						this.setState({ stats: stats, editing: false, loading: false });

						// this.setState((state, props) => {
						//   let stats = state.stats.slice();
						//   stats.push(stat);
						//   return {stats:stats};
						// });

						// this.closecreateStatDialog();

					})
					.catch(error => console.log('Error:', error));

			});

	}

	updateStat() {

		this.setState({ loading: true });

		let statToUpdate = Object.assign({}, this.state.current);
		statToUpdate.widget = this.props.statWidgetId;
		if (statToUpdate.calculation === 'From Graph') {
			statToUpdate.graph = statToUpdate.graph.id;
			statToUpdate.extract = null;
			statToUpdate.sensors = [];
			statToUpdate.weather_variables = null;
			statToUpdate.location_marker = null;

		} else if (statToUpdate.calculation === 'Forecast') {
			statToUpdate.extract = null;
			statToUpdate.sensors = [];
			statToUpdate.graph = null;
			statToUpdate.location_marker = statToUpdate.location_marker.id;
		} else {

			if (statToUpdate.sensors === undefined || statToUpdate.sensors.length == 0) {
				this.setState({
					loading: false,
					message: this.props.intl.formatMessage({ id: 'app.stat.sensorSelectMessage', defaultMessage: 'Please select at least one sensor!' })
				});
				return
			}

			statToUpdate.extract = statToUpdate.extract.id;


			let sensors = [];

			for (let i in statToUpdate.sensors)
				sensors.push(statToUpdate.sensors[i].id);

			statToUpdate.sensors = sensors;

			statToUpdate.graph = null;
			statToUpdate.weather_variables = null;
			statToUpdate.location_marker = null;

		}


		let url = `${BASEURI}/api/stats/rud/${statToUpdate.id}/`,
			options = {
				method: 'PUT',
				body: JSON.stringify(statToUpdate),
				headers: {
					"Content-Type": "application/json; charset=utf-8",
				}
			};

		getAuthToken()
			.then(token => token)
			.catch(token => token)
			.then(token => {

				options.headers.Authorization = `Bearer  ${token}`;

				fetch(url, options)
					.then((response) => response.json())
					.then(stat => {


						let statList = this.state.stats.slice();

						let statToReplace = null;
						for (let i = 0; i < statList.length; i++) {
							if (statList[i].id === stat.id) {
								statToReplace = i;
								break;
							}
						}

						statList.splice(statToReplace, 1, stat);

						// statList[statToReplace] = stat;

						this.setState({ stats: statList, editing: false, loading: false });

					})
					.catch(error => console.log('Error:', error));

			});

	}

	renameWidget(id, newName) {
		let widget = {
			id: id,
			name: newName
		}
		let url = `${BASEURI}/api/stats/widget/rud/${widget.id}/`,
			options = {
				method: 'PUT',
				body: JSON.stringify(widget),
				headers: {
					"Content-Type": "application/json; charset=utf-8",
				}
			};

		getAuthToken()
			.then(token => token)
			.catch(token => token)
			.then(token => {

				options.headers.Authorization = `Bearer  ${token}`;

				fetch(url, options)
					.then((response) => response.json())
					.catch(error => console.log('Error:', error));

			});

	}

	handleChange(event, range = undefined) {

		if (range) {
			this.setState(state => {
				let current = state.current;
				current.range = range;
				let min_date;
				let max_date;

				if (range === 180)
					min_date = new Date(new Date().setMonth(new Date().getMonth() - (range / 30)));
				else if (range === 365)
					min_date = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
				else
					min_date = new Date(new Date().setDate(new Date().getDate() - range));

				max_date = new Date();

				return {
					daterange: { start: min_date, end: max_date },
					current: current
				};
			})
			return;
		}

		let name = event.target.name, value = event.target.value;
		if (event.target.props.name === 'daterange')
			this.setState(state => {

				let current = state.current;
				current.min_date = value.start;
				current.max_date = value.end;
				current.range = null;
				return {
					daterange: value,
					current: current
				};
			}
			);
		else if (event.target.name === 'formula')
			this.setState(state => {

				let current = state.current;
				current.variables.formula = value;

				return { current: current };
			});
		else
			this.setState(state => {

				let current = state.current;
				current[name] = value;

				// We don't calculate last year for (From Graph, Forecast and Cumulative Sum)
				if (['From Graph', 'Forecast'].indexOf(current.calculation) !== -1) {
					current.last_year = false;
				}

				return { current: current };
			});


		if (event.target.name === 'sensors') {
			if (event.target.value.length > 0) {

				let url = `${BASEURI}/api/extracts/${event.target.value[0].id}/`,

					options = {
						method: 'GET',
						headers: {
							"Content-Type": "application/json; charset=utf-8",
						}
					};

				getAuthToken()
					.then(token => token)
					.catch(token => token)
					.then(token => {

						options.headers.Authorization = `Bearer  ${token}`;

						fetch(url, options)
							.then((response) => response.json())
							.then((extracts) => {
								this.setState({
									extracts: extracts
								});
							})
							.catch(error => console.log('Error:', error));
					});
			}
		}

	}

	initNewStat() {

		let newStat = {

			variables: {
				formula: ''
			},
			color: '#000000',
			min_date: new Date(new Date().setDate(new Date().getDate() - 7)),
			max_date: new Date()
		}

		this.loadGraphsList();

		this.setState({
			editing: true,
			action: 'new',
			current: newStat
		});
	}

	render() {

		let stats = this.state.stats.map((stat) =>
			<StatItem
				intl={this.props.intl}
				station={this.props.station}
				handleStationChange={this.props.handleStationChange}
				onClick={this.editStat}
				statId={stat.id}
				key={stat.id}
				data={stat}
			/>);

		return (
			<div className='col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-3 2xl:col-span-2'>
				<StatMenu
					intl={this.props.intl}
					renameWidget={this.renameWidget}
					name={this.props.widget.name}
					delete={this.props.delete}
					widgetId={this.props.statWidgetId}
					initNewStat={this.initNewStat} />

				<Modal>
					<StatModalForm
						message={this.state.message}
						intl={this.props.intl}
						stations={this.props.stations}
						station={this.props.station}
						setStations={this.props.setStations}
						allStations={this.props.allStations}
						handleStationChange={this.props.handleStationChange}
						visible={this.state.editing}
						close={this.closeModal}
						action={this.state.action}
						current={this.state.current}
						updateStat={this.updateStat}
						createStat={this.createStat}
						delete={this.deleteStat}
						statTitle={this.state.action === 'edit' ? this.state.current.title : ''}
						sensors={this.props.sensors}
						statId={this.state.current ? this.state.current.id : null}
						handleChange={this.handleChange}
						extracts={this.state.extracts}
						daterange={this.state.daterange}
						loading={this.state.loading}
						graphs={this.state.graphs}
						handleFilterChange={this.props.filter}
						handleGraphFilterChange={this.handleGraphFilterChange}
						weatherVariables={this.props.weatherVariables}
						markers={this.props.markers}
						getMarker={this.getMarker}
						handleMarkersFilterChange={this.props.handleMarkersFilterChange}
						noData={this.props.noData}
						loadingSensors={this.props.loadingSensors}
						loadingMarkers={this.props.loadingMarkers}
						loadingVariables={this.props.loadingWeatherVariables}
					/>
				</Modal>
				<table className="w-full rounded-md drop-shadow-md bg-slate-700" style={{ cursor: 'pointer' }}>
					{stats}
				</table>
			</div>
		);
	}

}


export default Stat;