import React, { useState, useEffect } from 'react'
import { Grid as KGrid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { useIntl } from 'react-intl';
import { formatDate } from '../utils';
import { orderBy } from '@progress/kendo-data-query';


function TableViewSwitch(props) {
  const intl = useIntl();
  return (
    <div>
      <button
        className=" mdl-button mdl-js-button mdl-button--icon text-slate-700"
        title={props.state ?
          intl.formatMessage({ id: 'app.chart.chartTable.tableView', defaultMessage: 'View Table' }) :
          intl.formatMessage({ id: 'app.chart.chartTable.viewChart', defaultMessage: 'View Chart' })}
        onClick={props.onClick}
      >
        {
          props.state ?
            <i className="material-icons">table_view</i> :
            <i className="material-icons">insert_chart</i>
        }
      </button>
    </div>
  )
}

export { TableViewSwitch }


function ChartTable(props) {

  const [sort, setSort] = useState([{ field: 'date', dir: 'desc' }])
  const [gridData, setGridData] = useState([]);
  const [graphLabels, setGraphLabels] = useState([]);
  const [shownColumns, setShownColumns] = useState([]);
  const intl = useIntl();



  function HeaderTitleCell(props) {
    return (
      <a className="k-link" onClick={props.onClick}>
        <span style={{ fontWeight: 'bold', fontSize: '1.2em' }} >{props.title}</span>
        {props.children}
      </a>
    );
  }

  function rowRenderer(trElement, props) {
    const date = props.dataItem.date;
    const day = date.getDay();
    const withBackground = day % 2 === 0;
    const gray = { backgroundColor: "rgb(0,0,0,0.03)" };
    const white = { backgroundColor: "rgb(255,255,255)" };
    const trProps = { style: withBackground ? gray : white };
    return React.cloneElement(trElement, { ...trProps }, trElement.props.children);


  }


  useEffect(() => {

    let data = {};
    let labels = [];

    for (let i = 0; i < props.graphs.length; i++) {
      const key = props.graphs[i].id,
        label = props.graphs[i].label;

      labels.push({
        name: label,
        key: key,
        show: true
      });

      for (let j = 0; j < props.graphs[i].data.length; j++) {
        let item = props.graphs[i].data[j];
        if (data.hasOwnProperty(item[0])) {
          data[item[0]][key] = item[1]
        } else {
          data[item[0]] = {
            date: new Date(item[0]),
            [key]: item[1]
          }
        }
      }
    }

    setGridData(Object.values(data));
    setGraphLabels(labels);
    setShownColumns(labels);
  }, []);

  const handleColumnVisibility = (selectedLabel) => {
    let newLabels = [];
    if (selectedLabel.show) {
      //check if selected label show property was true set it to false, and hides selected label column in grid
      newLabels = graphLabels.map(label => label.key === selectedLabel.key ? { ...label, show: false } : label);
      setGraphLabels(newLabels);
      setShownColumns(newLabels);
    }
    else {
      //if selected Label show property was false, set it back to true and shows selected label column in grid
      newLabels = graphLabels.map(label => label.key === selectedLabel.key ? { ...label, show: true } : label);
      setGraphLabels(newLabels);
      setShownColumns(newLabels);
    }
  }

  return (
    <KGrid
      style={{ height: 400 }}
      data={orderBy(gridData, sort)}
      sortable
      sort={sort}
      onSortChange={(e) => setSort(e.sort)}
      className='chart-table-view'
    >

      <GridToolbar>
        <div className="column-label-container">
          {shownColumns.map((label, i) =>
            <button title={label.show ? "Hide" : "Show"} key={i} onClick={() => handleColumnVisibility(label)} className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" >
              {
                label.name} {label.show ?
                  <i className="material-icons" style={{ marginLeft: 3 }}>visibility_on</i> :
                  <i className="material-icons" style={{ marginLeft: 3 }}>visibility_off</i>
              }
            </button>
          )}
        </div>
      </GridToolbar>

      <Column headerCell={HeaderTitleCell} field='date' format="{0:dd/MM/yy HH:mm}" title={intl.formatMessage({ id: 'app.chart.chartTable.date', defaultMessage: 'Date' })} locked />

      {
        graphLabels.length > 0 &&
        graphLabels.map((item, i) => {
          return item.show && <Column headerCell={HeaderTitleCell} key={i} field={item.key} title={`${item.name.toUpperCase()}`} />
        })
      }
    </KGrid>

  );
}

export default ChartTable;

