import React, { useState, useEffect } from "react";
import { useReducer } from "react";

import { SCOUTURI, SCOUTAPIKEY } from "../../utils";
import { buildParamStr } from "../utils";
import PlotStatCard from "./PlotStatCard";
import SettingsModal from "./SettingsModal";


function inEditReducer(state, action){
    switch(action.type){
        case 'select':
            return action.value
        case 'update':
            return {...state, ...action.value}
    }
}


export default function PlotStatCardContainer(props){

    const {farm, year} = props;
    const [plotStats, setPlotStats] = useState([]);
    const [inEdit, inEditDispatch] = useReducer(inEditReducer, {})
    const [showModal, setShowModal] = useState(false)
    const [isSaving, setIsSaving] = useState(false)

    function getStats(){

        if (!farm || !farm.id)
        return
    
        let params = buildParamStr({
            year: year
        })
    
        let url = `${SCOUTURI}/api/v1.0/plot/${farm.id}/stats/${params}`,
        options = {
            method:'GET',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            }
        };
        const APIKEY = SCOUTAPIKEY;
        options.headers.Authorization = `Api-Key ${APIKEY}`;
    
        fetch(url, options)
        .then((response) => {
            if(!response.ok){
                response.text().then(err => console.log(err));
                throw new Error(response.statusText, response.status);
            }
            return response.json();
        })
        .then((response) => {
            setPlotStats(response.sort((a, b) => a > b))
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    useEffect(() => getStats(), [farm, year])

    function editPlot(plotID){
        setShowModal(true)
        inEditDispatch({
            type: 'select',
            value: plotStats.find(item => item.plot_id === plotID)
        })

    }

    async function savePlot(){
        setIsSaving(true)

        if (!farm || !farm.id)
        return

        let params = buildParamStr({
            year: year,
            plot: inEdit.plot_id
        })

        let url = `${SCOUTURI}/api/v1.0/plot/${farm.id}/stats/${params}`,
        options = {
            method:'PUT',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify({name: inEdit.plot_name, to_kg_formula: inEdit.to_kg_formula})
        };
        options.headers.Authorization = `Api-Key ${SCOUTAPIKEY}`;
        const response = await fetch(url, options)
        if (!response.ok){
            throw new Error(response.statusText, response.status)
        }

        // Get new stats
        getStats()
        setIsSaving(false)
    }

    return <>
        <div className="grid grid-cols-12 gap-2 m-3">
            {
                plotStats.map(
                    stats => (
                        <PlotStatCard 
                            key={stats.plot_id}
                            stats={stats}
                            onEdit={editPlot}
                        />
                    )
                )
            }
        </div>
        <SettingsModal
            show={showModal}
            plot={inEdit}
            onClose={() => setShowModal(false)}
            onSave={savePlot}
            onChange={inEditDispatch}
            formula={inEdit?.to_kg_formula}
            saving={isSaving}
        />
    </>
}