import React, { useState, useRef, useEffect } from 'react';
import { Grid as KGrid, GridColumn as Column, GridToolbar, GridNoRecords } from '@progress/kendo-react-grid';
import { orderBy, filterBy } from '@progress/kendo-data-query';
import '@progress/kendo-theme-material';
import { GridCell } from '@progress/kendo-react-grid';
import { formatDate } from '../utils';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { useIntl } from 'react-intl';


function GridContainer(props) {

    return (
        <div className="mdl-grid mdl-grid--no-spacing station-grid--container" style={{ justifyContent: 'center' }}>

            <div className="mdl-cell mdl-cell--12-col">
                <div className='mdl-grid'>
                    {props.children}
                </div>
            </div>

        </div>
    );
}

function DateCell(props) {
    const { dataItem } = props;
    return (
        <td>
            {dataItem.last_transmission ? formatDate(dataItem.last_transmission) : ''}
        </td>
    )
}

function MapLinkCell(props) {
    const { dataItem } = props;
    const [disabled, setDisabled] = useState(false);
    const [link, setLink] = useState('')

    useEffect(() => {
        let lat, lng;
        if (dataItem.details) {
            if (dataItem.details.hasOwnProperty('location')) {
                lat = dataItem.details.location.lat;
                lng = dataItem.details.location.lng;
                setLink(`http://maps.google.com/maps?t=k&q=loc:${lat}+${lng}`);
            }
        } else {
            setDisabled(true)
        }
    }, [dataItem])

    return (
        <td>{
            disabled ?
                <i className='material-icons-outlined text-gray-500' style={{ cursor: 'not-allowed' }}>location_off</i> :
                <a href={link} target="_blank" >
                    <span className='hover:border-b-2 hover:border-b-blue-600'>
                        <i className='material-icons-outlined text-blue-600'>pin_drop</i>
                        <i className='material-icons-outlined text-blue-600' style={{ fontSize: '.9em', verticalAlign: 'top', marginLeft: -3 }}>north_east</i>
                    </span>

                </a>}
        </td>
    )
}

function UsersCell(props) {
    const { dataItem } = props;

    return <td>
        {dataItem.users.map(user => <span key={user.username}> {user.username} </span>)}
    </td>
}

function CommandCell({ edit, update, cancel, remove, editField }, intl) {
    return props => {
        // render() {
        const { dataItem } = props;
        const inEdit = dataItem[editField];

        return inEdit ? (
            <td className="k-command-cell">
                <button
                    className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-grid-save-command"
                    onClick={() => update(dataItem)}
                >
                    {intl.formatMessage({ id: "app.stationGrid.commandCdel.save", defaultMessage: "Save" })}
                </button>
                <button
                    className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-grid-cancel-command"
                    onClick={() => cancel(dataItem)}
                >
                    {intl.formatMessage({ id: "app.stationGrid.commandCdel.cancel", defaultMessage: "Cancel" })}
                </button>
            </td>
        ) : (
            <td className="k-command-cell">
                <button
                    className="k-primary k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-grid-edit-command"
                    onClick={() => edit(dataItem)}
                >
                    {intl.formatMessage({ id: "app.stationGrid.commandCdel.edit", defaultMessage: "Edit" })}
                </button>
                <button
                    className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-grid-delete-command"
                    style={{ color: 'red' }}
                    onClick={() => remove(dataItem)}
                >
                    {intl.formatMessage({ id: "app.stationGrid.commandCdel.delete", defaultMessage: "Delete" })}
                </button>
            </td>
        );
        // }
    }
};

function StationGrid(props) {

    const [sort, setSort] = useState([{ field: 'serial', dir: 'asc' }]);
    const [filter, setFilter] = useState([]);
    const [skip, setSkip] = useState(0);
    const [pageSize] = useState(10);
    const _export = useRef(null);
    const intl = useIntl();

    const cellWidth = 200;

    function pageChange(e) {
        setSkip(e.page.skip);
    }

    const downloadXls = () => {
        _export.current.save();
    }

    const Commands = CommandCell({
        edit: props.edit,
        update: props.update,
        cancel: props.cancel,
        remove: props.remove,
        editField: "inEdit"
    }, intl);

    return (
        <div ref={props.stationsRef} className='mdl-cell mdl-grid--no-spacing mdl-cell--8-col mdl-cell--8-col-tablet mdl-cell--4-col-phone mdl-shadow--2dp station-grid'>
            <ExcelExport
                data={props.data}
                ref={_export}
                fileName="Stations Grid"
            >
                <KGrid data={filterBy(orderBy(props.data, sort), filter).slice(skip, skip + pageSize)} scrollable={'virtual'} style={{ height: '500px' }}
                    sortable
                    sort={sort}
                    onSortChange={e => setSort(e.sort)}
                    filterable
                    skip={skip}
                    pageSize={pageSize}
                    onPageChange={pageChange}
                    total={props.data.length}
                    rowHeight={40}
                    filter={filter}
                    onFilterChange={e => { setFilter(e.filter); setSkip(0); }}
                    editField='inEdit'
                    onItemChange={props.onChange}
                >
                    <GridNoRecords>
                        {intl.formatMessage({ id: 'app.admin.graph_grid.no_data', defaultMessage: 'No Records Available' })}
                    </GridNoRecords>
                    <GridToolbar>
                        <div className='mdl-grid mdl-grid--no-spacing mdl-cell--12-col'>
                            <div className='mdl-cell mdl-cell--6-col ag-grid-title'>
                                {intl.formatMessage({ id: 'app.admin.stationGrid.station', defaultMessage: 'Stations' })}
                            </div>
                            <div className='ag-grid-link-button--ctn mdl-cell mdl-cell--6-col'>
                                <button className="mdl-button mdl-js-button mdl-button--primary" style={{ marginRight: 5 }} onClick={downloadXls}>{intl.formatMessage({ id: 'app.admin.stationGrid.download', defaultMessage: 'Download' })}</button>
                                <button className='ag-link-button ag-link-button--green-underline' onClick={(e) => props.setData(e, 'All')}>{intl.formatMessage({ id: 'app.admin.stationGrid.all', defaultMessage: 'All' })}</button>
                                <button className='ag-link-button ag-link-button--green-underline' onClick={(e) => props.setData(e, 'Normal')}>{intl.formatMessage({ id: 'app.admin.stationGrid.normal', defaultMessage: 'Normal' })}</button>
                                <button className='ag-link-button ag-link-button--green-underline' onClick={(e) => props.setData(e, 'Lagging')}>{intl.formatMessage({ id: 'app.admin.stationGrid.lagging', defaultMessage: 'Lagging' })}</button>
                                <button className='ag-link-button ag-link-button--green-underline' onClick={(e) => props.setData(e, 'Low Battery')}>{intl.formatMessage({ id: 'app.admin.stationGrid.lowBattery', defaultMessage: 'Low Battery' })}</button>
                            </div>
                        </div>

                    </GridToolbar>
                    <Column width={cellWidth} title={intl.formatMessage({ id: 'app.admin.stationGrid.name', defaultMessage: 'Name' })} field='name' />
                    <Column width={cellWidth} title={intl.formatMessage({ id: 'app.admin.stationGrid.serial', defaultMessage: 'serial' })} field='serial' editable={false} />
                    <Column width={cellWidth} title={intl.formatMessage({ id: 'app.admin.stationGrid.lastRecord', defaultMessage: 'Last Record' })} field='last_transmission' cell={DateCell} editable={false} />
                    <Column width={cellWidth} title={intl.formatMessage({ id: 'app.admin.stationGrid.Battery', defaultMessage: 'Battery %' })} field='details.battery' editable={false} />
                    <Column width={cellWidth} title={intl.formatMessage({ id: 'app.admin.stationGrid.users', defaultMessage: 'Users' })} field='users[0].username' cell={UsersCell} editable={false} />
                    <Column width={cellWidth} title={intl.formatMessage({ id: 'app.admin.stationGrid.notes', defaultMessage: 'Notes' })} field='notes' />
                    <Column width={100} title={intl.formatMessage({ id: 'app.admin.stationGrid.map', defaultMessage: 'View in Map' })} field='details' cell={MapLinkCell} editable={false} filterable={false} sortable={false} />
                    <Column width={150} cell={Commands} filterable={false} sortable={false} />
                </KGrid>
            </ExcelExport>
        </div>
    );
}


export default StationGrid;
export { GridContainer };