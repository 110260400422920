import React, { Component } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import loading from '../loading.svg';
import Spinner from '../general/Spinner';

class StatModalForm extends Component {
	constructor(props) {
		super(props);
		this.state = {

		}
		this.handleStationFilterChange = this.handleStationFilterChange.bind(this);
	}


	functions = ['Min', 'Max', 'Avg', 'Sum', 'Cumulative Sum', 'Last Record']
	functionsWithoutCumSum = ['Min', 'Max', 'Avg', 'Sum', 'Last Record']
	calculations = ['Custom Formula', 'Raw', 'From Graph', 'Forecast']


	valueRender(element, value) {
		if (!value) {
			return element;
		}
		const children = [
			<span>{value.device === null ? value.location_name : value.device.name}</span>
		];
		return React.cloneElement(element, { ...element.props }, children);
	}
	markerItemRender(li, itemProps) {
		const itemChildren = <div>{itemProps.dataItem.device === null ? itemProps.dataItem.location_name : `${itemProps.dataItem.device.name}[${itemProps.dataItem.device.serial}]`} </div>;
		return React.cloneElement(li, li.props, itemChildren);
	}

	itemRender(li, itemProps) {

		const itemChildren = <div> {li.props.children} <div style={{ color: "#00F" }}> {itemProps.dataItem.device.name} [{itemProps.dataItem.port}]</div> </div>;
		return React.cloneElement(li, li.props, itemChildren);
	}

	extractItemRender(li, itemProps) {
		const itemChildren = <div> {itemProps.dataItem.description} [{itemProps.dataItem.unit && itemProps.dataItem.unit}] </div>;
		return React.cloneElement(li, li.props, itemChildren);
	}

	graphListItemRender(li, itemProps) {

		const itemChildren = <div> {li.props.children} [{itemProps.dataItem.calculation}] <div style={{ color: "#00F" }}> {itemProps.dataItem.chart.name} </div> </div>;
		return React.cloneElement(li, li.props, itemChildren);
	}

	handleStationFilterChange(e) {
		let filtered = this.props.stations.filter(item => {
			let filterText = e.filter.value,
				itemText = `${item.name.toLowerCase()}`;

			for (let i in filterText.split(' ')) {
				let textSplit = filterText.split(' ')[i];

				if (itemText.indexOf(textSplit) !== -1)
					return true
				else
					return false
			}
			return true;
		});

		this.props.setStations(filtered);
		if (e.filter.value === "") {
			this.props.setStations(this.props.allStations);
		}
	}

	render() {

		let selected = {
			border: '1px solid #1ce4b1'
		},
			range = parseInt(Math.abs(this.props.daterange.end - this.props.daterange.start) / (1000 * 60 * 60 * 24));
		console.log('action ', this.props.action)
		let actionBar;
		if (this.props.action === 'edit' || this.props.action === 'delete') {
			actionBar =
				<DialogActionsBar>
					<button
						className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md delete"
						onClick={() => { this.props.delete(this.props.statId) }}
					>
						{this.props.loading && this.props.action === 'delete'
							?
							<img src={loading} style={{ width: '20px', opacity: .5 }} alt='loading' />
							:
							this.props.intl.formatMessage({ id: 'app.stat.statDialog.delete', defaultMessage: 'Delete' })
						}
					</button>
					<button
						className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md"
						onClick={this.props.close}
					>
						{this.props.intl.formatMessage({ id: 'app.stat.statDialog.done', defaultMessage: 'Done' })}
					</button>
					<button
						className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md"
						onClick={this.props.updateStat}
					>
						{this.props.loading && this.props.action === 'edit'
							?
							<img src={loading} style={{ width: '20px', opacity: .5 }} alt='loading' />
							:
							this.props.intl.formatMessage({ id: 'app.stat.statDialog.update', defaultMessage: 'Update' })
						}
					</button>
				</DialogActionsBar>;
		} else {
			actionBar =
				<DialogActionsBar>
					<button
						className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md"
						onClick={this.props.close}
					>
						{this.props.intl.formatMessage({ id: 'app.stat.statDialog.done', defaultMessage: 'Done' })}
					</button>
					<button
						className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md"
						onClick={this.props.createStat}
					>
						{this.props.loading
							?
							<img src={loading} style={{ width: '20px', opacity: .5 }} alt='loading' />
							:
							this.props.intl.formatMessage({ id: 'app.stat.statDialog.add', defaultMessage: 'Add' })
						}
					</button>
				</DialogActionsBar>;
		}

		let form;

		if (this.props.visible && this.props.action !== 'loading') {

			form = <div className='modal-form-inputs-wrap'>
				<div className="modal-form-inputs-wrap-row">
					<DropDownList
						label={this.props.intl.formatMessage({ id: 'app.chart.chartDialog.station', defaultMessage: 'STATION' })}
						name="station"
						textField="name"
						filterable={true}
						onFilterChange={this.handleStationFilterChange}
						onChange={this.props.handleStationChange}
						data={this.props.stations}
						value={this.props.station}
					/>
					<MultiSelect key='sensor-select'
						name='sensors'
						listNoDataRender={this.props.loadingSensors ? this.props.noData : null}
						required={true}
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.sensor', defaultMessage: 'SENSOR(S)' })}
						data={this.props.sensors}
						value={this.props.current.sensors}
						textField='name'
						dataItemKey='id'
						itemRender={this.itemRender}
						onChange={this.props.handleChange}
						filterable={true}
						onFilterChange={this.props.handleFilterChange}
						style={{ height: 'auto' }}
					/>
				</div>
				<div className="modal-form-inputs-wrap-row">
					<DropDownList
						key='extract-select'
						name='extract'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.sensorExtract', defaultMessage: 'SENSOR EXTRACT' })}
						data={this.props.extracts}
						value={this.props.current.extract}
						textField='description'
						itemRender={this.extractItemRender}
						dataItemKey='id'
						onChange={this.props.handleChange}
					/>
					{this.props.current.calculation === 'Custom Formula' &&

						<div style={{ marginTop: '20px', color: 'gray' }}>
							<span style={{ paddingRight: '20px' }}>
								{this.props.intl.formatMessage({ id: 'app.stat.statDialog.eM50FlowMeter', defaultMessage: 'Em50 Flow Meter' })}
							</span>
							<Switch
								name='em50_flow_meter'
								checked={this.props.current.em50_flow_meter}
								onChange={this.props.handleChange}
							/>
						</div>}
				</div>
			</div>;

			if (this.props.current.calculation === 'Forecast' && this.props.action !== 'loading') {
				form = <div className='modal-form-inputs-wrap'>
					<div className="modal-form-inputs-wrap-row">

						<DropDownList
							key='marker-select'
							name='location_marker'
							required={true}
							label={this.props.intl.formatMessage({ id: 'app.chart.forecastForm.markers', defaultMessage: 'MARKER(S)' })}
							data={this.props.markers}
							value={this.props.current.location_marker}
							dataItemKey='id'
							onChange={this.props.handleChange}
							itemRender={this.markerItemRender}
							valueRender={this.valueRender}
							filterable={true}
							onFilterChange={this.props.handleMarkersFilterChange}
							listNoDataRender={this.props.loadingMarkers ? this.props.noData : null}
						/>
						<DropDownList
							key='weatherVariabe-select'
							name='weather_variable'
							label={this.props.intl.formatMessage({ id: 'app.chart.forecastForm.weatherVariables', defaultMessage: 'WEATHER VARIABLES' })}
							data={this.props.weatherVariables}
							value={this.props.current.weather_variable}
							onChange={this.props.handleChange}
							listNoDataRender={this.props.loadingVariables ? this.props.noData : null}
						/>
					</div>

				</div>;
			}
			if (this.props.current.calculation === 'From Graph' && this.props.action !== 'loading')
				form = <div className='modal-form-inputs-wrap'>
					<div className="modal-form-inputs-wrap-row">
						<DropDownList
							key='graph-select'
							name='graph'
							required={true}
							label={this.props.intl.formatMessage({ id: 'app.alert.addEditAlertDialog.graphs', defaultMessage: 'GRAPH(S)' })}
							data={this.props.graphs}
							value={this.props.current.graph}
							textField='label'
							dataItemKey='id'
							onChange={this.props.handleChange}
							itemRender={this.graphListItemRender}
							filterable={true}
							onFilterChange={this.props.handleGraphFilterChange}
						/>
					</div>

				</div>;
		}

		let dialog = this.props.visible &&
			<Dialog title={this.props.intl.formatMessage({ id: 'app.stat.statDialog.editStat', defaultMessage: 'Edit Stat' })} onClose={this.props.close} className='edit-chart-dialog'>

				<div className='modal-wrap' style={{ justifyContent: 'center' }}>
					{this.props.action === 'loading'
						?
						<div className='modal-form-wrap' >
							<div className="stat-loading-indicator">
								<Spinner
									color={'black'}
									show={true}
									backDrop={true}
									backDropColor={'white'}
									backDropOpacity={0.6}
									size={20}
								/>
							</div>
						</div>
						:
						<div>
							<div className='modal-form-wrap'>
								<div className='modal-form-inputs-wrap'>
									<div className="modal-form-inputs-wrap-row">
										<DropDownList
											label={this.props.intl.formatMessage({ id: 'app.stat.statDialog.function', defaultMessage: 'FUNCTION' })}
											name='function'
											data={this.props.current.calculation === 'From Graph' || this.props.current.calculation === 'Forecast' ? this.functionsWithoutCumSum : this.functions}
											value={this.props.current.function}
											onChange={this.props.handleChange}
										/>
										<DropDownList
											label={this.props.intl.formatMessage({ id: 'app.chart.chartDialog.calculation', defaultMessage: 'CALCULATION' })}
											name='calculation'
											data={this.calculations}
											value={this.props.current.calculation}
											onChange={this.props.handleChange}
										/>
									</div>

								</div>

								{form}

								<div className='modal-form-inputs-wrap'>
									<div className="modal-form-inputs-wrap-row">
										<Input
											name='title'
											required={true}
											key='title-input'
											label={this.props.intl.formatMessage({ id: 'app.stat.statDialog.title', defaultMessage: 'TITLE' })}
											value={this.props.current.title}
											onChange={this.props.handleChange}
										/>
										<Input
											name='formula'
											key='formula-input'
											label={this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.formula', defaultMessage: 'FORMULA' })}
											value={this.props.current.variables.formula}
											onChange={this.props.handleChange}
										/>
									</div>
								</div>

								{this.props.current.function !== 'Cumulative Sum' ?

									<div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between' }}>
										<div className='modal-calendar-wrap'>
											<DateRangePicker
												name='daterange'
												value={this.props.daterange}
												calendarSettings={{ views: 1 }}
												popupSettings={this.popupSettings}
												onChange={this.props.handleChange}
											/>
										</div>
										{/* We don't calculate last year for (From Graph, Forecast and Cumulative Sum) */}
										{['From Graph', 'Forecast'].indexOf(this.props.current.calculation) === -1 &&

											<div style={{ margin: '30px', marginLeft: "0px", padding: "10px" }}>
												<span style={{ paddingRight: '20px' }}>
													{this.props.intl.formatMessage({ id: 'app.stat.statDialog.lastYearValue', defaultMessage: 'LAST YEAR VALUE' })}
												</span>
												<Switch
													name='last_year'
													checked={this.props.current.last_year}
													onChange={this.props.handleChange}
												/>
											</div>}
									</div> :
									<div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between' }}>
										<div>
											<Input
												name='reset_on'
												title={this.props.intl.formatMessage({ id: 'app.stat.statDialog.resetOn', defaultMessage: 'RESET ON (MM/DD)' })}
												label={this.props.intl.formatMessage({ id: 'app.stat.statDialog.resetOn', defaultMessage: 'RESET ON (MM/DD)' })}
												value={this.props.current.reset_on}
												onChange={this.props.handleChange}
											/>
										</div>

										<div style={{ margin: '30px', marginLeft: "0px", padding: "10px" }}>
											<span style={{ paddingRight: '20px' }}>
												{this.props.intl.formatMessage({ id: 'app.stat.statDialog.lastYearValue', defaultMessage: 'LAST YEAR VALUE' })}
											</span>
											<Switch
												name='last_year'
												checked={this.props.current.last_year}
												onChange={this.props.handleChange}
											/>
										</div>
									</div>
								}
							</div>
							{this.props.current.function !== 'Cumulative Sum' &&
								<div>
									<button
										onClick={(e) => this.props.handleChange(e, 365)}
										className='k-button k-button-md k-button-solid k-button-solid-base k-rounded-md ag-range-btn'
										style={range >= 360 ? selected : {}}>
										{this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.1y', defaultMessage: '1Y' })}
									</button>
									<button
										onClick={(e) => this.props.handleChange(e, 180)}
										className='k-button k-button-md k-button-solid k-button-solid-base k-rounded-md ag-range-btn'
										style={range >= 180 && range < 360 ? selected : {}}>
										{this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.6m', defaultMessage: '6M' })}
									</button>
									<button
										onClick={(e) => this.props.handleChange(e, 30)}
										className='k-button k-button-md k-button-solid k-button-solid-base k-rounded-md ag-range-btn'
										style={range === 30 ? selected : {}}>
										{this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.1m', defaultMessage: '1M' })}
									</button>
									<button
										onClick={(e) => this.props.handleChange(e, 7)}
										className='k-button k-button-md k-button-solid k-button-solid-base k-rounded-md ag-range-btn'
										style={range === 7 ? selected : {}}>
										{this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.1w', defaultMessage: '1W' })}
									</button>
									<button
										onClick={(e) => this.props.handleChange(e, 2)}
										className='k-button k-button-md k-button-solid k-button-solid-base k-rounded-md ag-range-btn'
										style={range === 2 ? selected : {}}>
										{this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.48H', defaultMessage: '48H' })}
									</button>
									<button
										onClick={(e) => this.props.handleChange(e, 1)}
										className='k-button k-button-md k-button-solid k-button-solid-base k-rounded-md ag-range-btn'
										style={range === 1 ? selected : {}}>
										{this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.24H', defaultMessage: '24H' })}
									</button>
								</div>
							}
						</div>
					}

					{this.props.action !== 'loading' &&
						<div className='modal-spacer'>
							&nbsp;
						</div>
					}

					<div style={{ textAlign: 'center' }}><span style={{ color: 'red' }}>{this.props.message}</span></div>
				</div>

				{actionBar}

			</Dialog>;
		return (dialog);
	}
}

export default StatModalForm;