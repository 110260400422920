import React, { Component } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Input, Switch } from '@progress/kendo-react-inputs';
import Highcharts from 'highcharts';
import xrange from 'highcharts/modules/xrange';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import Modal from '../general/Modal';
import { getAuthToken, BASEURI, formatDate } from '../utils';
import loading from '../loading.svg';
import loadingWhite from '../loading_white.svg';
import crop from '../images/leafs.png';
import plot from '../images/smart-farm1.png'
import { RefreshPortal } from '../general/Layout';
import RefreshButton from '../general/RefreshButton';
import ConfirmDeleteDialog from '../general/ConfirmDeleteDialog';

var cardBtn = {
  'background': 'transparent',
  'border': 'none',
  'cursor': 'pointer',
  'padding': '2px',
  'color': 'inherit'
};

class StationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      serial: '',
      password: '',
      api: 'Zentra',
      download_since: null,
    };

    this.handleChange = this.handleChange.bind(this);
  }


  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });


  }

  componentDidUpdate() {
    this.props.handleChange(this.state);
  }

  render() {

    return (
      <div className='modal-form-inputs-wrap'>
        <div className="modal-form-inputs-wrap-row">
          <DropDownList
            label={this.props.intl.formatMessage({ id: 'app.stations.stationForm.api', defaultMessage: 'API' })}
            name='api'
            data={["Ech2o", "Zentra", "Fieldclimate", "Wiseconn", "Agronic", "Talgil", "Davis", "Qampo"]}
            value={this.state.api}
            onChange={this.handleChange} />

          <Input
            required={true}
            name='name'
            label={this.props.intl.formatMessage({ id: 'app.stations.stationForm.name', defaultMessage: 'NAME' })}
            value={this.state.name}
            onChange={this.handleChange} />
        </div>
        <div className="modal-form-inputs-wrap-row">
          <Input
            required={true}
            name='serial'
            label={this.props.intl.formatMessage({ id: 'app.stations.stationForm.serialNo', defaultMessage: 'SERIAL NO' })}
            value={this.state.serial}
            onChange={this.handleChange} />

          <Input
            required={true}
            name='password'
            label={this.props.intl.formatMessage({ id: 'app.stations.stationForm.password', defaultMessage: 'PASSWORD' })}
            value={this.state.password}
            onChange={this.handleChange} />
        </div>
        <div className="modal-form-inputs-wrap-row">
          <div style={{ display: 'flex', flexFlow: 'column' }}>
            <label style={{ color: 'gray', fontSize: '.8em', paddingTop: '5px' }}>
              {this.props.intl.formatMessage({ id: 'app.stations.stationForm.downloadDataSince', defaultMessage: 'DOWNLOAD DATA SINCE' })}
            </label>
            <DatePicker
              name='download_since'
              value={this.state.download_since}
              calendarSettings={{ views: 1 }}
              popupSettings={this.popupSettings}
              onChange={this.handleChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

class ModalForm extends Component {

  constructor(props) {
    super(props);
    this.station = {
      name: '',
      password: '',
      serial: '',
      download_since: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(station) {
    this.station = station;
  }

  render() {

    let dialog = this.props.visible &&
      <Dialog title={this.props.intl.formatMessage({ id: 'app.stations.modelFrom.addStation', defaultMessage: 'Add Station' })} onClose={this.props.close} className='add-station-dialog'>

        <div className='modal-wrap'>
          <div className='modal-form-wrap'>
            <StationForm intl={this.props.intl} handleChange={this.handleChange} />
          </div>
          <div style={{ color: 'red', padding: '10px' }}>
            {this.props.error}
          </div>
        </div>
        <DialogActionsBar>
          <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={this.props.close}>{this.props.intl.formatMessage({ id: 'app.stations.modelFrom.cancel', defaultMessage: 'Cancel' })}</button>
          <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={() => { this.props.addStation(this.station) }}>
            {this.props.loading ? <img style={{ color: 'gray', width: '25px' }} src={loading} alt='loading'></img> : this.props.intl.formatMessage({ id: 'app.stations.modelFrom.add', defaultMessage: 'Add' })}
          </button>
        </DialogActionsBar>
      </Dialog>;
    return (dialog);
  }
}


class ViewStation extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tab: null,
      message: '',
      addingChart: false,
      daterange: { start: new Date(new Date().setDate(new Date().getDate() - 30)), end: new Date() },
      range: 1,
      loading: false,
      loadingForecastData: false,
      allGraphs: [],
      filteredGraphs: [],
      showConfirmDeleteDialog: false,
      generatingReports: false
    }

    this.changeDateRange = this.changeDateRange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.update_sensors = this.update_sensors.bind(this);
    this.getAllGraphs = this.getAllGraphs.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.itemRender = this.itemRender.bind(this);
    this.handleGraphFilterChange = this.handleGraphFilterChange.bind(this);
    this.downloadForecastData = this.downloadForecastData.bind(this);
    this.autoAddIrrigationChart = this.autoAddIrrigationChart.bind(this);
    this.generateReports = this.generateReports.bind(this);
  }

  autoAddIrrigationChart() {

    if (!this.state.tab) {
      this.setState({ message: this.props.intl.formatMessage({ id: 'app.stations.viewStation.message', defaultMessage: 'Please select tab!' }) });
      return
    };

    this.setState({ addingChart: true });

    let url = `${BASEURI}/api/irrigation/auto-add-chart/`,
      options = {
        body: JSON.stringify(
          {
            device: this.props.serial,
            tab: this.state.tab.id,
          }
        ),
        method: 'POST',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };
    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        //no token exists or all tokens are expired
        if (token === false) {
          return false;

        }

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            if (!response.ok) {
              response.text().then(err => console.log(err));
              throw new Error(response.statusText, response.status);
            }
            return response.json();
          })
          .then((response) => {
            this.setState({
              addingChart: false,
              message: response.message,
            });
          })
          .catch(error => {
            console.error('Error:', error);
          });
      });

  }

  generateReports() {
    this.setState({ generatingReports: true });

    let url = `${BASEURI}/api/reports/generate-reports/${this.props.serial}`,
      options = {
        method: 'get',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };
    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        //no token exists or all tokens are expired
        if (token === false) {
          return false;

        }

        options.headers.Authorization = `Bearer  ${token}`;


        fetch(url, options)
          .then((response) => {
            if (!response.ok) {
              response.text().then(err => console.log(err));
              throw new Error(response.statusText, response.status);
            }
            return response.json();
          })
          .then((e) => {
            this.setState({
              generatingReports: false,
            });
          })
          .catch(error => {
            console.error('Error:', error);
          });
      });
  }

  downloadForecastData(serial) {
    this.setState({ loadingForecastData: true });

    let url = `${BASEURI}/api/dashboard/stations/marker/download/`,
      options = {
        // station serial is provided instead of marker id
        // for that reason you must set station value 'true' 
        body: JSON.stringify({ 'station': serial }),
        method: 'POST',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };
    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        //no token exists or all tokens are expired
        if (token === false) {
          return false;

        }

        options.headers.Authorization = `Bearer  ${token}`;


        fetch(url, options)
          .then((response) => {
            if (!response.ok) {
              response.text().then(err => console.log(err));
              throw new Error(response.statusText, response.status);
            }
            return response.json();
          })
          .then((e) => {
            this.setState({
              loadingForecastData: false,
            });
          })
          .catch(error => {
            console.error('Error:', error);
          });
      });

  }

  changeDateRange(range) {
    let daterange = {
      start: new Date(new Date().setMonth(new Date().getMonth() - range)),
      end: new Date()
    }
    this.setState({ range: range, daterange: daterange });
  }

  handleChange(event) {

    if (event.target.name === 'tab') {
      this.setState({ tab: event.target.value, message: '' });
    } else if (event.target.name === 'regionCode') {
      this.setState({ regionCode: event.target.value })
    }
    else {
      this.setState({ daterange: event.target.value });
    }


  }

  getAllGraphs() {
    let url = `${BASEURI}/api/allgraphs/`,
      options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            return response.json();
          })
          .then((allGraphs) => {
            this.setState({ allGraphs: allGraphs, filteredGraphs: allGraphs });
          })
          .catch(error => console.error('Error:', error));

      });

  }

  handleGraphFilterChange(event) {
    this.setState(() => {
      return {
        filteredGraphs: this.state.allGraphs.slice().filter(item => {
          let filterText = event.filter.value,
            itemText = `${item.label.toLowerCase()} ${item.chart.name.toLowerCase()} [${item.calculation.toLowerCase()}]`;

          for (let i in filterText.split(' ')) {
            let textSplit = filterText.split(' ')[i];
            // if (item.name.toLowerCase().indexOf(textSplit) !== -1 || item.device.name.toLowerCase().indexOf(textSplit) !== -1 || item.port.indexOf(textSplit) !== -1)
            if (itemText.indexOf(textSplit) !== -1)
              return true
            else
              return false
          }
          return true;
        })
      };
    });
  }

  handleDropdownChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  componentDidMount() {
    this.getAllGraphs();
  }

  update_sensors() {

    this.setState({ loading: true });

    let url = `${BASEURI}/api/stations/sensors/${this.props.serial}/`,
      options = {
        method: 'PUT',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            return response.json();
          })
          .then(() => {
            this.setState({
              loading: false,
            });
          })
          .catch(error => console.error('Error:', error));

      });
  }

  itemRender(li, itemProps) {
    const itemChildren = <div> {li.props.children} [{itemProps.dataItem.calculation}] <div style={{ color: "#00F" }}> {itemProps.dataItem.chart.name} </div> </div>;
    return React.cloneElement(li, li.props, itemChildren);
  }

  render() {
    // const {intl} = this.props
    let selected = {
      border: '1px solid #1ce4b1'
    };

    let dialog = this.props.visible &&
      <Dialog title={this.props.name} onClose={this.props.close}>

        <div className='modal-wrap station-dialog-ctn'>
          <fieldset>
            <legend>
              {this.props.intl.formatMessage({ id: 'app.stations.viewStation.stationDetail', defaultMessage: 'STATION DETAIL' })}
            </legend>
            <div className='mdl-grid'
            // style={{marginLeft: '40px'}}
            >
              <div className='mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--4-col-phone'>
                <Input
                  required={false}
                  name='name'
                  label={this.props.intl.formatMessage({ id: 'app.stations.viewStation.name', defaultMessage: 'NAME' })}
                  value={this.props.name}
                  onChange={this.props.handleChange}
                />
              </div>
              <div className='mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--4-col-phone'>
                <Input
                  required={false}
                  name='plot'
                  label={this.props.intl.formatMessage({ id: 'app.stations.viewStation.plot', defaultMessage: 'PLOT' })}
                  value={this.props.plot}
                  onChange={this.props.handleChange}
                />
              </div>
              <div className='mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--4-col-phone'>
                <Input
                  required={false}
                  name='crop'
                  label={this.props.intl.formatMessage({ id: 'app.stations.viewStation.crop', defaultMessage: 'CROP' })}
                  value={this.props.crop}
                  onChange={this.props.handleChange}
                />
              </div>
              <div className='mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--4-col-phone station-dialog-button'>
                <button onClick={this.props.rename} className='k-button k-button-md k-button-solid k-button-solid-base k-rounded-md'>
                  {this.props.renaming ?
                    <img style={{ color: 'gray', width: '25px' }} src={loading} alt='loading'></img> :
                    this.props.intl.formatMessage({ id: 'app.stations.viewStation.save', defaultMessage: 'Save' })}
                </button>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <legend>
              {this.props.intl.formatMessage({ id: 'app.stations.viewStation.statonData', defaultMessage: 'STATION DATA' })}
            </legend>
            <XRangeChart
              serial={this.props.serial}
              data={this.props.data}
              loading={this.props.details !== null && this.props.details.download_in_progress !== undefined ? this.props.details.download_in_progress : false}
            />

            {this.props.details !== null && this.props.details.download_in_progress !== undefined && this.props.details.download_in_progress ?
              <div>
                {this.props.intl.formatMessage({ id: 'app.stations.viewStation.backgroundDownload', defaultMessage: 'Background download in progress' })} <img style={{ color: 'gray', width: '25px' }} src={loading} alt='loading'></img>
              </div>
              : null
            }
            <div>
              <DateRangePicker
                calendarSettings={{ views: 1 }}
                value={this.state.daterange}
                onChange={this.handleChange}
              // popupSettings = {this.popupSettings}
              />
              <div>
                <button
                  onClick={() => this.changeDateRange(12)}
                  className='k-button k-button-md k-button-solid k-button-solid-base k-rounded-md ag-range-btn'
                  style={this.state.range === 12 ? selected : {}}>
                  {this.props.intl.formatMessage({ id: 'app.stations.viewStation.1Y', defaultMessage: '1Y' })}
                </button>
                <button
                  onClick={() => this.changeDateRange(6)}
                  className='k-button k-button-md k-button-solid k-button-solid-base k-rounded-md ag-range-btn'
                  style={this.state.range === 6 ? selected : {}}>
                  {this.props.intl.formatMessage({ id: 'app.stations.viewStation.6M', defaultMessage: '6M' })}
                </button>
                <button
                  onClick={() => this.changeDateRange(3)}
                  className='k-button k-button-md k-button-solid k-button-solid-base k-rounded-md ag-range-btn'
                  style={this.state.range === 3 ? selected : {}}>
                  {this.props.intl.formatMessage({ id: 'app.stations.viewStation.3M', defaultMessage: '3M' })}
                </button>
                <button
                  onClick={() => this.changeDateRange(1)}
                  className='k-button k-button-md k-button-solid k-button-solid-base k-rounded-md ag-range-btn'
                  style={this.state.range === 1 ? selected : {}}>
                  {this.props.intl.formatMessage({ id: 'app.stations.viewStation.1M', defaultMessage: '1M' })}
                </button>
              </div>
              <div>
                <button
                  className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-flat ag-range-btn"
                  style={{ color: 'green', width: 'auto', minWidth: '200px' }}
                  onClick={() => this.props.download({
                    range: {
                      start: formatDate(this.state.daterange.start),
                      end: formatDate(this.state.daterange.end)
                    },
                    device: this.props.serial
                  })
                  }>
                  {this.props.loading ?
                    <img style={{ width: '20px' }} src={loading} alt='loading' /> :
                    this.props.intl.formatMessage({ id: 'app.stations.viewStation.downloadStationData', defaultMessage: 'Download Station Data' })}
                </button>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <legend>
              {this.props.intl.formatMessage({ id: 'app.stations.viewStation.irrigation', defaultMessage: 'IRRIGATION' })}
            </legend>
            <div className='mdl-grid'>
              <div className='mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--4-col-phone'>
                <DropDownList
                  label={this.props.intl.formatMessage({ id: 'app.stations.viewStation.tab', defaultMessage: 'TAB' })}
                  name='tab'
                  dataItemKey='id'
                  textField='name'
                  data={this.props.tabs}
                  onChange={this.handleChange}
                />
                <span style={{ color: 'red', marginTop: '10px' }}>{this.state.message}</span>
              </div>
              <div className='mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--4-col-phone'>
                <button
                  title='Auto Add Irrigation Chart'
                  className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-flat ag-range-btn"
                  style={{ color: 'green', width: 'auto', minWidth: '200px' }}
                  onClick={this.autoAddIrrigationChart}
                >
                  {this.state.addingChart ?
                    <img style={{ width: '20px' }} src={loading} alt='loading' /> :
                    this.props.intl.formatMessage({ id: 'app.stations.viewStation.autoAddIrrigation', defaultMessage: "Auto Add Irrigation Chart" })}
                </button>

              </div>
            </div>
          </fieldset>
          <fieldset>
            <legend>
              {this.props.intl.formatMessage({ id: 'app.stations.viewStation.reports', defaultMessage: 'WEATHER REPORTS' })}
            </legend>
            <div >
              <button
                title={this.props.intl.formatMessage({ id: 'app.stations.viewStation.generateReports', defaultMessage: "Generate Reports" })}
                className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-flat ag-range-btn"
                style={{ color: 'green', width: 'auto', minWidth: '200px' }}
                onClick={this.generateReports}
              >
                {this.state.generatingReports ?
                  <img style={{ width: '20px' }} src={loading} alt='loading' /> :
                  this.props.intl.formatMessage({ id: 'app.stations.viewStation.generateReports', defaultMessage: "Generate Reports" })}
              </button>
            </div>
          </fieldset>
          <fieldset>
            <legend>
              {this.props.intl.formatMessage({ id: 'app.stations.viewStation.weatherForecast', defaultMessage: 'WEATHER FORECAST' })}
            </legend>
            <div >
              <span
                style={{ paddingRight: '20px' }}
                title='Use Station Data For Forecast'
              >
                {this.props.intl.formatMessage({ id: 'app.stations.viewStation.mlForecast', defaultMessage: 'ML Forecast' })}
              </span>
              <Switch
                name='ml_forecast'
                checked={this.props.ml_forecast}
                onChange={this.props.handleStationDetailChange}
              />
              <div style={{ marginTop: '10px', color: 'gray' }}>
                {
                  this.props.ml_forecast_remain_days
                }
              </div>
              <div>
                <div style={{ marginTop: '10px' }}>
                  <button
                    title={this.props.intl.formatMessage({ id: 'app.stations.viewStation.downloadForecastData', defaultMessage: 'Download Forecast Data' })}
                    className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-flat ag-range-btn"
                    style={{ color: 'green', width: 'auto', minWidth: '200px' }}
                    onClick={() => { this.downloadForecastData(this.props.serial) }}>
                    {this.state.loadingForecastData ? <img style={{ width: '20px' }} src={loading} alt='loading' /> : this.props.intl.formatMessage({ id: 'app.stations.viewStation.downloadForecastData', defaultMessage: 'Download Forecast Data' })}
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <legend>
              {this.props.intl.formatMessage({ id: 'app.stations.viewStation.frostMonitoring', defaultMessage: 'FROST MONITORING' })}
            </legend>
            <span
              style={{ paddingRight: '20px' }}
              title='Use Station Data For Forecast'
            >
              {this.props.intl.formatMessage({ id: 'app.stations.viewStation.frostmoni', defaultMessage: 'Frost Monitoring' })}
            </span>
            <Switch
              name='frost_monitoring'
              checked={this.props.frost_monitoring}
              onChange={this.props.handleStationDetailChange}
              disabled={this.props.ml_forecast ? false : true}
            />
          </fieldset>
          <fieldset>
            <legend>
              {this.props.intl.formatMessage({ id: 'app.stations.viewStation.well', defaultMessage: 'WELL' })}
            </legend>
            <span
              style={{ paddingRight: '20px' }}
              title={this.props.intl.formatMessage({ id: 'app.stations.viewStation.wellDescription', defaultMessage: 'Activate to add chart for well' })}
            >
              {this.props.intl.formatMessage({ id: 'app.stations.viewStation.wellStation', defaultMessage: 'Well Station' })}
            </span>
            <Switch
              name='is_well_station'
              checked={this.props.is_well_station}
              onChange={this.props.handleStationDetailChange}
            />
          </fieldset>
          {this.state.showConfirmDeleteDialog && <ConfirmDeleteDialog
            onClose={() => this.setState({ showConfirmDeleteDialog: false })}
            serial={this.props.serial}
            remove={() => {
              this.props.removeStation(this.props.serial);
              this.setState({ showConfirmDeleteDialog: false });
            }}
          />}
        </div>
        <DialogActionsBar>

          <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md delete" onClick={() => { this.setState({ showConfirmDeleteDialog: true }) }}>{this.props.intl.formatMessage({ id: 'app.stations.viewStation.remove', defaultMessage: 'Remove' })}</button>
          <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={this.update_sensors}>
            {this.state.loading ? <img style={{ color: 'gray', width: '25px' }} src={loading} alt='loading'></img> : this.props.intl.formatMessage({ id: 'app.stations.viewStation.updateSensor', defaultMessage: 'Update Sensors' })}
          </button>
          <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={this.props.close}>{this.props.intl.formatMessage({ id: 'app.stations.viewStation.done', defaultMessage: 'Done' })}</button>
        </DialogActionsBar>
      </Dialog>;
    return (dialog);

  }

}

class StationCard extends Component {
  render() {

    return (

      <div className="mdl-cell mdl-cell--8-col">
        <div className="ag-station-card mdl-card mdl-shadow--2dp">
          <div className="mdl-card__title">
            <h4>
              {this.props.name} [{this.props.serial}]
            </h4>
            <div className="ag-card-menu">
              <a className="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect" href="#dummy">
                {
                  this.props.verified ?
                    <i className="material-icons" style={{ 'color': '#7bd47b' }}>check_circle_outline</i> :
                    <i className="material-icons" style={{ 'color': 'red' }}>error_outline</i>
                }
                <span> {this.props.intl.formatDate(this.props.last_transmission, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })} </span>
              </a>
              <button style={cardBtn} onClick={() => this.props.viewStation(this.props.serial)}><i className="material-icons">more_vert</i></button>

            </div>

            {/* <h5> */}
            {/* {this.props.status} */}
            {/* </h5> */}
          </div>


          <div className="ag-card-text mdl-card__supporting-text">
            <img src={plot} ></img><span>{this.props.plot}</span><br />
            <img src={crop} ></img><span>{this.props.crop}</span>
          </div>
        </div>
      </div>
    );
  }
}

class XRangeChart extends React.PureComponent {

  initialize() {

    xrange(Highcharts);
    Highcharts.setOptions({
      global: {
        useUTC: false
      },
      lang: {
        loading: `<img src="${loading}" style="width:40px;">`
      }
    });

    this.chart = Highcharts.chart(this.props.serial, {
      chart: {
        type: 'xrange',
        style: {
          fontFamily: 'inherit'
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: null
      },
      xAxis: {
        type: 'datetime'
      },
      yAxis: {
        title: {
          text: ''
        },
        categories: ['data'],
        reversed: true
      },
      legend: {
        enabled: false
      },
      loading: {
        labelStyle: {
          backgroundColor: 'rgba(255,255,255,0.5)',
          opacity: 1,
          position: 'relative',
          display: 'inline-block',
          // width: '136px',
          // height: '100px',
          top: '45%'
        }
      },
      series: [{
        data: this.props.data
      }],
      exporting: {
        enabled: false
      }
    });
  }

  componentDidMount() {
    this.initialize();
    if (!this.props.data) {
      this.chart.showLoading();
      return;
    }
  }

  componentDidUpdate() {
    if (!this.props.data) {
      this.chart.showLoading();
      return;
    }
    this.initialize();
  }

  render() {

    return <div id={this.props.serial} style={{ 'height': '150px' }}></div>;

  }
}

class Stations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stations: [],
      searchInput: '',
      showDialog: false,
      viewedStation: null,
      viewStation: false,
      stationData: [],
      loading: false,
      error: '',
      renaming: false,
      notInitial: false,
    }

    // fetch('/api/stations/')
    // .then((response) => {
    //   return response.json();
    // })
    // .then((stations) => {
    //   this.setState({
    //     stations:stations
    //   });
    // })
    // .catch(error => console.error('Error:', error));

    this.addStation = this.addStation.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.loadStations = this.loadStations.bind(this);
    this.viewStation = this.viewStation.bind(this);
    this.download = this.download.bind(this);
    this.removeStation = this.removeStation.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.rename = this.rename.bind(this);
    this.filterStations = this.filterStations.bind(this);
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.handleStationDetailChange = this.handleStationDetailChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {

    this.loadStations();

    // document.getElementById('refresh').addEventListener('click', this.refresh);

    // Refresh list of stations every 30 seconds
    this.setState({
      interval: setInterval(this.refresh, 30 * 1000)
    });

  }

  componentWillUnmount() {
    // document.getElementById('refresh').removeEventListener('click', this.refresh);
    clearInterval(this.state.interval);
  }

  refresh = () => {
    this.loadStations();
    if (this.state.viewStation)
      this.viewStation(this.state.viewedStation.serial);
  }

  loadStations() {
    if (this.state.notInitial) {
      this.setState({ loading: true });
    }
    let url = `${BASEURI}/api/stations/`,
      options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            return response.json();
          })
          .then((stations) => {
            if (!this.state.notInitial) {
              this.setState({ notInitial: true });
            }
            this.setState({
              loading: false,
              stations: stations,
            });
          })
          .catch(error => console.error('Error:', error));

      });
  }

  openDialog() {
    this.setState({
      showDialog: true
    });
  }

  closeDialog() {
    this.setState({
      showDialog: false,
      error: ''
    });
  }

  addStation(station) {
    //validate station serial/password format before adding
    let serialRegex, passRegex;
    if (station.api === 'Fieldclimate') {
      serialRegex = /^[A-Z0-9]{8}$/;
      passRegex = /^[A-Za-z0-9]{5}$/;
    } else if (station.api === 'Ech2o') {
      serialRegex = /^[A-Z0-9]{8}$/;
      passRegex = /^[a-z0-9]{4,5}-[a-z0-9]{4,5}$/;
    } else if (station.api === 'Zentra') { //Zentra api
      serialRegex = /[z,0]6-\d{5}$/;
      passRegex = /^\d{5}-\d{5}$/;
    } else {
      serialRegex = /[A-Za-z0-9]/;
      passRegex = serialRegex;
    }

    if (!serialRegex.test(station.serial) || !passRegex.test(station.password)) {
      this.setState({ error: `Inconsistent serial or password format for ${station.api} API` })
      return;
    }

    this.setState({ error: '', loading: true });

    let url = `${BASEURI}/api/stations/`,
      options = {
        method: 'POST',
        body: JSON.stringify(station),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            if (response.status !== 200 && response.status !== 201) {
              response.text().then(text => this.setState({ 'error': text, loading: false }));
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .then((stations) => {
            this.setState((state, props) => {
              let prev_stations = state.stations.slice();
              prev_stations.push(stations);
              return { stations: prev_stations, loading: false };
            });

            this.closeDialog();

          })
          .catch(error => {
            console.log(error);
            this.setState({ loading: false });
          });

      });

  }

  viewStation(serial) {

    for (let i = 0; i < this.state.stations.length; i++) {
      let station = this.state.stations[i];
      if (station.serial === serial)
        this.setState({
          'viewedStation': station,
          'viewStation': true,
          'stationData': station.ranges
        })
    }

    let url = `${BASEURI}/api/stations/${serial}/`,
      options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            return response.json();
          })
          .then((station) => {
            let allStations = this.state.stations.slice();

            for (let i in allStations) {
              if (station.serial === serial) {
                this.setState({
                  'stationData': station.ranges,
                  viewedStation: station
                });
                break;
              }
            }

            // this.setState({
            //   stations: stations,
            // });
          })
          .catch(error => console.error('Error:', error));

      });

  }


  download(params) {

    // let data = {
    //   start: formatDate
    // }

    this.setState({ loading: true });

    let url = `${BASEURI}/api/stations/download/`,
      options = {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            if (!response.ok) {
              response.text().then(err => console.log(err));
              throw new Error(response.statusText, response.status);
            }
            return response.json()
          })
          .then((changedStation) => {

            let index = this.state.stations.findIndex(s => s.serial === changedStation.serial);
            let stations = this.state.stations.slice()
            stations.splice(index, 1, changedStation);
            this.setState({ stations: stations, loading: false });
            this.viewStation(params.device);
          })
          .catch(error => console.log('Error:', error));

      });
  }

  removeStation(serial) {

    this.setState({ loading: true });

    let url = `${BASEURI}/api/stations/${serial}/`,
      options = {
        method: 'DELETE',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {

            this.setState((state, props) => {
              let prev_stations = state.stations.slice();
              for (let i = 0; i < prev_stations.length; i++) {
                if (prev_stations[i].serial === serial) {
                  prev_stations.splice(i, 1);
                  return { stations: prev_stations, loading: false, viewStation: false };
                }
              }

              throw new Error('Deleted station not found.');

            });

          })
          .catch(error => console.log('Error:', error));

      });

  }

  handleStationDetailChange(event) {
    // Use only this function for ml_forcast, frost_monitoring and is_well_station state change 

    const value = event.target.value;

    if (event.target.name === 'is_well_station') {
      this.setState({
        viewedStation: {
          ...this.state.viewedStation,
          is_well_station: value,
        }
      });
      this.submit({ is_well_station: value });
      return
    }

    if (event.target.name === 'ml_forecast') {

      this.setState({
        viewedStation: {
          ...this.state.viewedStation,
          ml_forecast: value,
        }
      });
      this.submit({ ml_forecast: value });
    } else {
      this.setState({
        viewedStation: {
          ...this.state.viewedStation,
          frost_monitoring: value,
        }
      });
      this.submit({ frost_monitoring: value });
    }
  }

  submit(value) {
    let url = `${BASEURI}/api/stations/${this.state.viewedStation.serial}/`,
      options = {
        method: 'PUT',
        body: JSON.stringify({
          ...value
        }),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => response.json())
          .then((stations) => {
            this.setState({ renaming: false, stations: stations });
          })
          .catch(error => console.log('Error:', error));

      });
  }

  rename() {

    this.setState({ renaming: true });
    let url = `${BASEURI}/api/stations/${this.state.viewedStation.serial}/`,
      options = {
        method: 'PUT',
        body: JSON.stringify({
          name: this.state.viewedStation.name,
          plot: this.state.viewedStation.plot,
          crop: this.state.viewedStation.crop,
        }),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => response.json())
          .then((stations) => {
            this.setState({ renaming: false, stations: stations });
          })
          .catch(error => console.log('Error:', error));

      });
  }

  handleChange(event) {
    this.setState({
      viewedStation: {
        ...this.state.viewedStation,
        [event.target.name]: event.target.value
      }
    });

  }

  handleSearchInputChange(event) {
    this.setState({
      searchInput: event.target.value
    });
  }

  filterStations() {

    return this.state.stations.filter((station) => {

      let item = `${station.name.toLowerCase()} [${station.serial.toLowerCase()}]`;
      // return item.includes(this.state.searchInput.toLowerCase())
      return item.indexOf(this.state.searchInput.toLowerCase()) !== -1
    });
  }

  render() {
    let stations = this.filterStations().map((station) => {
      return (<StationCard intl={this.props.intl} key={station.serial} viewStation={this.viewStation} {...station} />);
    });

    return (

      <div className="mdl-grid ag-stations-grid">

        <div className='breadcrumb mdl-grid mdl-cell mdl-cell--12-col'>
          {this.props.intl.formatMessage({ id: 'app.stations.stations', defaultMessage: 'STATIONS' })}
        </div>
        <RefreshPortal>
          <RefreshButton onClick={this.refresh} loading={this.state.loading} />
        </RefreshPortal>
        <div className="mdl-cell mdl-cell--8-col" style={{ marginBottom: '30px' }}>
          <Input
            placeholder={this.props.intl.formatMessage({ id: 'app.stations.search', defaultMessage: 'Search' })}
            onChange={this.handleSearchInputChange}
            style={{ width: '100%' }}
            value={this.state.searchInput}
          />
        </div>
        {stations}

        <button onClick={this.openDialog} className="mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored mdl-shadow--4dp mdl-color--primary ag-add-button">
          <i className="material-icons" role="presentation">add</i>
          <span className="visuallyhidden">Add</span>
        </button>
        <Modal>
          <ModalForm
            intl={this.props.intl}
            visible={this.state.showDialog}
            close={this.closeDialog}
            addStation={this.addStation}
            error={this.state.error}
            loading={this.state.loading}
          />
          <ViewStation
            tabs={this.props.tabs}
            visible={this.state.viewStation}
            close={() => this.setState({ viewStation: false, viewedStation: null, stationData: [] })}
            {...this.state.viewedStation}
            data={this.state.stationData}
            loading={this.state.loading}
            download={this.download}
            removeStation={this.removeStation}
            handleChange={this.handleChange}
            rename={this.rename}
            renaming={this.state.renaming}
            handleStationDetailChange={this.handleStationDetailChange}
            intl={this.props.intl}
          />
        </Modal>
      </div>

    );
  }
}

export default Stations;