import React, { useState, useCallback, useEffect, createContext, useReducer } from 'react';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { useIntl } from 'react-intl';
import { getAuthToken, BASEURI } from '../utils';
import { FormWithCustomEditor } from './CustomForm';
import {
    Scheduler,
    SchedulerItem,
    TimelineView,
    DayView,
    WeekView,
    MonthView,
    AgendaView,
} from '@progress/kendo-react-scheduler';
import IrrigationEvents from './IrrigationEvents';
import TaskFormModal from './TaskFormModal';
import loadingIcon from '../loading_white.svg';


const customModelFields = {
    id: 'id',
    title: 'title',
    description: 'description',
    start: 'start',
    end: 'end',
    recurrenceRule: 'recurrence_rule',
    isAllDay: 'is_all_day',
    recurrenceId: 'recurrence_id',
    recurrenceExceptions: 'recurrence_exceptions'
};

const CustomItem = (props) => {
    let color = '#c5fad4';

    return <SchedulerItem
        {...props}
        style={{ ...props.style, backgroundColor: color }}
    />
}

export const StationContext = createContext();

function IrrigationScheduler(props) {
    const intl = useIntl();
    const [data, setData] = useState([]);
    const [view, setView] = useState('week');
    const [date, setDate] = useState(new Date());
    const [station, setStation] = useState();
    const [stations, setStations] = useState([]);
    const [stationsCopy, setStationsCopy] = useState([]);

    const [sectors, setSectors] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [addingTask, setAddingTask] = useState(false);
    const [deletingTask, setDeletingTask] = useState(false);


    function handleStationFilterChange(e) {
        let filtered = stations.filter(item => {
            let filterText = e.filter.value,
                itemText = `${item.name.toLowerCase()}`;

            for (let i in filterText.split(' ')) {
                let textSplit = filterText.split(' ')[i];

                if (itemText.indexOf(textSplit) !== -1)
                    return true
                else
                    return false
            }
            return true;
        });
        setStationsCopy(filtered)
    }

    const parseData = (dataItems) => {

        return dataItems.map((item) => ({
            ...item,
            recurrence_exceptions: item.recurrence_exceptions &&
                item.recurrence_exceptions.map((rec_date) => new Date(rec_date)),
            start: new Date(item.start),
            end: new Date(item.end),
        }));
    }

    useEffect(() => {
        loadTasks();
    }, [])

    useEffect(() => {
        loadDeviceSectors(station);
    }, [station])

    useEffect(() => {
        loadDevices();
    }, [])

    const loadDevices = () => {

        let url = BASEURI + `/api/scheduler/devices/`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })

                    .then((devices_list) => {
                        console.log('Device >>>', devices_list);
                        setStations(devices_list);
                        setStationsCopy(devices_list);

                    })

                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }


    const loadDeviceSectors = (serial) => {

        let url = BASEURI + `/api/scheduler/sectors/${serial}`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })

                    .then((sectors_list) => {
                        setSectors(sectors_list);
                    })

                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }


    const loadTasks = () => {


        let url = BASEURI + '/api/scheduler/tasks/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })

                    .then((tasks) => {
                        console.log('the tasks', tasks);
                        setData(parseData(tasks));
                    })

                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }

    const deleteTask = (task) => {
        setDeletingTask(true);

        let url = BASEURI + `/api/scheduler/tasks/${task.id}/`,
            options = {
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })

                    .then((tasks) => {
                        setDeletingTask(false);
                        setData(parseData(tasks));
                    })

                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }


    const updateTask = (task) => {
        setAddingTask(true);

        task.device = task.device.serial;
        task.sector = task.sector.id;

        let url = BASEURI + `/api/scheduler/tasks/${task.id}/`,
            options = {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(task)
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })

                    .then((tasks) => {
                        setAddingTask(false);
                        setData(parseData(tasks));
                    })

                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });

    }

    const createTask = (task) => {
        setAddingTask(true);

        task.device = task.device.serial;
        task.sector = task.sector.id;

        console.log('before creation..', task);
        let url = BASEURI + '/api/scheduler/tasks/',
            options = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(task)
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    // this.setState({loggedin:false});
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })

                    .then((tasks) => {
                        setAddingTask(false);
                        setData(parseData(tasks));
                    })

                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }

    const handlViewChange = useCallback((event) => {
        setView(event.view);
    }, [setView])

    const handleDateChange = useCallback(
        (event) => {
            setDate(event.value);
        },
        [setDate]
    );

    const handleDataChange = useCallback(
        ({ created, updated, deleted }) => {

            if (deleted.length) {
                deleteTask(deleted[0]);
            }

            if (updated.length) {
                console.log('before update', updated[0]);
                updateTask(updated[0]);
            }

            if (created.length) {
                console.log('Before creation:>', created[0]);
                createTask(created[0]);
            }
        }
    )

    return (
        <div className="bg-slate-100 p-0 m-0">
            <div class="w-full p-4 m-1 bg-white text-xl text-gray-500 font-semibold rounded-2xl">
                {intl.formatMessage({ id: 'app.scheduler.events.schedulerTitle', defaultMessage: 'IRRIGATION SCHEDULER' })}
            </div>

            <div className='grid gap-1 grid-cols-12'>

                <div className='col-span-12 md:col-span-4 lg:col-span-3 order-last md:order-first rounded-md h-screen overflow-y-auto'>
                    <IrrigationEvents
                        stations={stationsCopy}
                        handleStationFilterChange={handleStationFilterChange}
                        sectors={sectors}
                        setStation={setStation} />
                </div>

                <div className='col-span-12 md:col-span-8 lg:col-span-9 rounded-2xl bg-white p-4 m-1'>
                    <StationContext.Provider value={stationsCopy}>
                        <Scheduler
                            item={CustomItem}
                            onDataChange={handleDataChange}
                            data={data}
                            onViewChange={handlViewChange}
                            view={view}
                            onDateChange={handleDateChange}
                            date={date}
                            editable={true}
                            modelFields={customModelFields}
                            form={FormWithCustomEditor}
                        >
                            {/* <TimelineView /> */}
                            <DayView />
                            <WeekView />
                            <MonthView />
                            <AgendaView />
                        </Scheduler>
                    </StationContext.Provider>
                </div>


                <span className="ag-add-widget-button">
                    <button
                        className="mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored mdl-shadow--4dp mdl-color--accent"
                        onClick={() => setShowDialog(!showDialog)}
                    >
                        <i className="material-icons" role="presentation">add</i>
                        <span className="visuallyhidden">
                            'Add'
                        </span>
                    </button>
                </span>
                {
                    showDialog &&
                    <TaskFormModal
                        stations={stationsCopy}
                        handleStationFilterChange={handleStationFilterChange}
                        sectors={sectors}
                        setStation={setStation}
                        loadTasks={loadTasks}
                        onClose={setShowDialog}
                    />
                }
                <NotificationGroup
                    style={{
                        right: 20,
                        bottom: 0,
                        alignItems: 'flex-start',
                        flexWrap: 'wrap-reverse',
                        zIndex: 100,
                    }}>
                    <Fade>
                        {deletingTask &&
                            <Notification
                                type={{
                                    style: 'error',
                                    // icon: true
                                }}
                                closable={true}
                                style={{ padding: '5px' }}
                                onClose={() => setAddingTask(false)}>
                                <img
                                    style={{ width: '20px', display: 'inline', paddingRight: '3px', verticalAlign: 'text-top' }}
                                    src={loadingIcon}
                                    alt='loading' />

                                <span>
                                    {intl.formatMessage({ id: 'app.scheduler.events.deleting', defaultMessage: 'Deleting...' })}
                                </span>
                            </Notification>}


                        {addingTask &&
                            <Notification
                                type={{
                                    style: 'success',
                                    // icon: true
                                }}
                                closable={true}
                                style={{ padding: '5px', background: '#16a34a' }}
                                onClose={() => setAddingTask(false)}>
                                <img
                                    style={{ width: '20px', display: 'inline', paddingRight: '3px', verticalAlign: 'text-top' }}
                                    src={loadingIcon}
                                    alt='loading' />

                                <span>
                                    {intl.formatMessage({ id: 'app.scheduler.events.adding', defaultMessage: 'Adding...' })}
                                </span>
                            </Notification>}
                    </Fade>
                </NotificationGroup>

            </div >
        </div>
    )
}

export default IrrigationScheduler;