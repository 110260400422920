import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useIntl } from 'react-intl';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import React, { useEffect, useState } from 'react'
import Modal from '../../general/Modal';
import { BASEURI, getAuthToken } from '../../utils';

function ConfigModal(props) {
    const [stations, setStations] = useState(props.stations);
    const [allStations, _] = useState(props.stations); // using all
    const [selectedStation, setSelectedStation] = useState(null);
    const [stationConfig, setStationConfig] = useState({});
    const [globalConfig, setGlobalConfig] = useState({});
    const [message, setMessage] = useState("");
    const [globalMessage, setGlobalMessage] = useState("");
    const intl = useIntl();
    useEffect(() => {
        if (selectedStation) {
            getStationConfig();
        }
    }, [selectedStation]);

    useEffect(() => {
        getGlobalConfig();
    }, []);


    function getGlobalConfig() {
        let url = BASEURI + `/api/reports/global-configs/`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then(resp => resp.json())
                    .then(config => {
                        // console.log(config.id);
                        setGlobalConfig(config)
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });

    }

    function getStationConfig() {
        let url = BASEURI + `/api/reports/configs/${selectedStation.serial}/`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then(resp => resp.json())
                    .then(config => {
                        console.log(config.id);
                        setStationConfig(config)
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });

    }

    function handleChange(e) {
        setSelectedStation(e.value);
    }

    function saveConfigurations() {
        if (selectedStation !== null) {
            saveStationConfig();
        }
        saveGlobalConfig();

    }

    function saveGlobalConfig() {
        setGlobalMessage("");
        let url = BASEURI + `/api/reports/global-configs/`,
            options = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(globalConfig),
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then(resp => {
                        if (resp.status === 200) { setGlobalMessage("Global Configuration saved succesfully."); }
                        else { setGlobalMessage("An error occured!") }
                        return resp.json();
                    })
                    .then(config => {
                        setGlobalConfig(config);
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }


    function saveStationConfig() {
        setMessage("");
        let url = BASEURI + `/api/reports/configs/${selectedStation.serial}/`,
            options = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(stationConfig),
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then(resp => {
                        if (resp.status === 200) { setMessage("Station Configuration saved succesfully."); }
                        return resp.json();
                    })
                    .then(config => {
                        setStationConfig(config);
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }


    function handleFilterChange(e) {
        let filtered = stations.filter(item => {
            let filterText = e.filter.value,
                itemText = `${item.name.toLowerCase()}`;

            for (let i in filterText.split(' ')) {
                let textSplit = filterText.split(' ')[i];

                if (itemText.indexOf(textSplit) !== -1)
                    return true
                else
                    return false
            }
            return true;
        });

        setStations(filtered);
        if (e.filter.value === "") {
            setStations(allStations);
        }
    }


    return (
        <Modal>
            <Dialog title={intl.formatMessage({ id: "app.report.weatherReport.configurations", defaultMessage: 'Configurations' })} onClose={props.onClose} height="600px">
                <div className="mdl-grid">
                    <ConfigurationForm title="User Configurations" data={globalConfig} setData={setGlobalConfig} message={globalMessage} />
                    <div className="mdl-grid mdl-cell mdl-cell--12-col">
                        <div className="mdl-cell mdl-cell--12-col mdl-cell-8-col-tablet conf-hint" >
                            {intl.formatMessage({ id: "app.report.weatherReport.message", defaultMessage: '* Provide station specific configuration in case it is different from global configuration.' })}
                        </div>
                        <div className="mdl-cell mdl-cell--6-col mdl-cell-4-col-tablet">
                            <DropDownList
                                style={{ width: '100%' }}
                                filterable={true}
                                onFilterChange={handleFilterChange}
                                data={stations}
                                onChange={handleChange}
                                label={intl.formatMessage({ id: "app.report.weatherReport.selectStation", defaultMessage: 'Select Station' })}
                                textField="name"
                                value={selectedStation} />
                        </div>
                        <div className="mdl-grid mdl-cell mdl-cell--6-col">
                        </div>
                    </div>
                    {
                        selectedStation &&
                        <ConfigurationForm
                            title={intl.formatMessage({ id: "app.report.weatherReport.StationConfigs", defaultMessage: 'Station Configurations' })}
                            data={stationConfig}
                            setData={setStationConfig}
                            message={message} />
                    }
                </div>
                <DialogActionsBar>
                    <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-primary" onClick={saveConfigurations}>
                        {intl.formatMessage({ id: "app.report.weatherReport.save", defaultMessage: 'Save' })}
                    </button>
                    <button onClick={props.onClose} className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" >
                        {intl.formatMessage({ id: "app.report.weatherReport.close", defaultMessage: 'Close' })}
                    </button>
                </DialogActionsBar>
            </Dialog>

        </Modal>
    );
}
export default ConfigModal;

function ConfigurationForm(props) {
    const intl = useIntl();

    function handleInputChange(e) {

        props.setData({ ...props.data, [e.target.name]: e.value });
    }

    return (
        <div className="mdl-grid mdl-cell mdl-cell--12-col mdl-cell--8-col--tablet mdl-cell--4-col-phone">
            <div className="form-ctn mdl-cell mdl-cell--6-col mdl-cell mdl-cell--4-col-tablet mdl-cell--2-col-phone"><b>{props.title}</b></div>
            <div className="mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--2-col-phone"></div>
            <div className="mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--2-col-phone">
                {intl.formatMessage({ id: "app.report.weatherReport.resetOn", defaultMessage: 'Reset On' })}
            </div>
            <div className="mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--2-col-phone">
                {intl.formatMessage({ id: "app.report.weatherReport.threshold", defaultMessage: 'Threshold' })}
            </div>

            <div className="mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--2-col-phone">
                <Input style={{ width: "100%" }} value={props.data.chill_hours_reset_on} name="chill_hours_reset_on" onChange={handleInputChange}
                    label={intl.formatMessage({ id: "app.report.weatherReport.chillHours", defaultMessage: 'Chill Hours' })} />
            </div>
            <div className="mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--2-col-phone threshold-ctn">
                <Input style={{ width: "100%" }} value={props.data.chill_hours_threshold} name="chill_hours_threshold" onChange={handleInputChange} label="c" />
            </div>
            <div className="mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--2-col-phone">
                <Input style={{ width: "100%" }} value={props.data.degree_days_reset_on} name="degree_days_reset_on" onChange={handleInputChange}
                    label={intl.formatMessage({ id: "app.report.weatherReport.degreeDays", defaultMessage: 'Degree Days' })} />
            </div>
            <div className="mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--2-col-phone threshold-ctn">
                <Input style={{ width: "100%" }} value={props.data.degree_days_threshold} name="degree_days_threshold" onChange={handleInputChange} label="c" />
            </div>
            <div className="mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--2-col-phone">
                <Input style={{ width: "100%" }} value={props.data.cherry_growing_reset_on} name="cherry_growing_reset_on" onChange={handleInputChange}
                    label={intl.formatMessage({ id: "app.report.weatherReport.cherryGrowing", defaultMessage: 'Cherry Growing' })} />
            </div>
            <div className="mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--2-col-phone threshold-ctn">
                <Input style={{ width: "100%" }} value={props.data.cherry_growing_threshold} name="cherry_growing_threshold" onChange={handleInputChange} label="c" />
            </div>
            <div className="mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--2-col-phone">
                <Input style={{ width: "100%" }} value={props.data.chill_portions_reset_on} name="chill_portions_reset_on" onChange={handleInputChange}
                    label={intl.formatMessage({ id: "app.report.weatherReport.chillPortions", defaultMessage: 'Chill Portions' })} />
            </div>
            <div className="mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--2-col-phone"></div>
            <div className="mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--2-col-phone">
                <Input style={{ width: "100%" }} value={props.data.precipitation_reset_on} name="precipitation_reset_on" onChange={handleInputChange}
                    label={intl.formatMessage({ id: "app.report.weatherReport.precipitation", defaultMessage: 'Precipitation' })} />
            </div>

            <div className="mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--2-col-phone"></div>
            <div className="mdl-cell mdl-cell--12-col mdl-cell mdl-cell--8-col-tablet">
                {props.message && <p style={{ marginTop: 5 }}>{props.message}</p>}
            </div>
        </div>
    );
}