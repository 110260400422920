import React from 'react';
import { useIntl } from 'react-intl';


function LogItem (props) {
    const intl = useIntl();

    return (
        <tr name='alert_row'>
            <td className="mdl-data-table__cell--non-numeric" style={{whiteSpace: 'pre-line'}}>{props.region_code}</td>
            {/* <td className="mdl-data-table__cell--non-numeric">{props.status_code}</td> */}
            {/* <td className="mdl-data-table__cell--non-numeric">{props.status}</td> */}
            <td className="mdl-data-table__cell--non-numeric" style={{whiteSpace: 'pre-line'}}>{props.description}</td>
            <td className="mdl-data-table__cell--non-numeric" style={{whiteSpace: 'pre-line'}}>{intl.formatDate(props.date, {
                year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'
            })}</td>
        </tr>
    );
}


function LogTable(props) {
    const intl = useIntl(); 

    return <div className='mdl-cell mdl-cell--8-col' style={{'overflowX': 'scroll'}}>
        <form className='k-form'>
            <fieldset>
                <legend>{intl.formatMessage({id:'app.dga.jobTable.uploadLogs', defaultMessage:'Upload Logs'})}</legend>
                <table className="mdl-data-table mdl-js-data-table" style={{width: '100%', marginTop:'10px'}}>
                    <thead>
                        <tr >
                            <th className="mdl-data-table__cell--non-numeric" style={{textAlign:"left"}}>
                                {intl.formatMessage({id:'app.dga.logTable.regionCode', defaultMessage:'Region Code'})}
                            </th>
                            {/* <th className="mdl-data-table__cell--non-numeric" style={{textAlign:"left"}}>
                                {intl.formatMessage({id:'app.dga.logTable.statusCode', defaultMessage:'Status Code'})}
                            </th> */}
                            {/* <th style={{textAlign:"left"}}>{intl.formatMessage({id:'app.dga.logTable.status', defaultMessage:'Status'})}</th> */}
                            <th style={{textAlign:"left"}}>{intl.formatMessage({id:'app.dga.logTable.description', defaultMessage:'Description'})}</th>
                            <th style={{textAlign:"left"}}>{intl.formatMessage({id:'app.dga.logTable.date', defaultMessage:'Date'})}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.children}
                    </tbody>
                </table>
            </fieldset>
        </form>
    </div>;
}

export default LogTable;
export {LogItem};