import React, { useState, useEffect } from 'react';
import { BASEURI, getAuthToken } from '../utils';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Grid as KGrid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { orderBy, filterBy } from '@progress/kendo-data-query';
import { useIntl } from 'react-intl';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import Modal from '../general/Modal';


const CommandCell = props => {
    const intl = useIntl();

    const { dataItem } = props;
    const inEdit = dataItem[props.editField];
    const isNewItem = dataItem.id === undefined;

    return inEdit ? (
        <td className="k-command-cell">
            <button
                className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-grid-save-command"
                onClick={() => (isNewItem ? props.add(dataItem) : props.update(dataItem))}
            >
                {isNewItem ?
                    intl.formatMessage({ id: 'app.admin.filterMarker.add', defaultMessage: "Add" }) :
                    intl.formatMessage({ id: 'app.admin.filterMarker.update', defaultMessage: "Update" })}
            </button>
            <button
                className="k-button k-grid-cancel-command"
                onClick={() => (isNewItem ? props.discard(dataItem) : props.cancel(dataItem))}
            >
                {isNewItem ?
                    intl.formatMessage({ id: 'app.admin.filterMarker.discard', defaultMessage: "Discard" }) :

                    intl.formatMessage({ id: 'app.admin.filterMarker.cancel', defaultMessage: "Cancel" })}
            </button>
        </td>
    ) : (
        <td className="k-command-cell">
            <button
                className="k-primary k-button k-grid-edit-command"
                onClick={() => props.edit(dataItem)}
            >
                {intl.formatMessage({ id: 'app.admin.filterMarker.edit', defaultMessage: "Edit" })}
            </button>
            <button
                className="k-button k-grid-remove-command"
                onClick={() =>
                    window.confirm(intl.formatMessage({ id: 'app.admin.filterMarker.deleteMessage', defaultMessage: "Confirm deleting: " }) + dataItem.name) &&
                    props.remove(dataItem)
                }
            >
                {intl.formatMessage({ id: 'app.admin.filterMarker.remove', defaultMessage: "Remove" })}
            </button>
        </td>
    );
};
function MarkerCell(props) {
    const { dataItem } = props;
    const inEdit = dataItem[props.editField];
    // const isNewItem = dataItem.id === undefined;
    const [markers, setMarkers] = useState([]);

    useEffect(() => { setMarkers(props.markersList) }, [props.markersList]);

    function handleFilterChange(event) {
        let filterText = event.filter.value;
        let filtered = props.markersList.slice().filter(item => {
            let domain = `${item.device && item.device.name + ' ' + item.device.serial} ${item.location_name}`;
            if (domain.indexOf(filterText) !== -1)
                return true;
            else
                return false;
        });

        setMarkers(filtered);

    }

    function tagRender(tagData, li) {
        const itemChildren = <span className="custom-tag">{tagData.data[0].device !== null ?
            `${tagData.data[0].device.name}[${tagData.data[0].device.serial}] ` :
            tagData.text}</span>;
        const items = [itemChildren];
        return React.cloneElement(li, li.props, [items]);
    }
    function tagRenderInEdit(tagData, li) {
        const itemChildren = <span className="custom-tag">{tagData.data[0].device !== null ?
            `${tagData.data[0].device.name}[${tagData.data[0].device.serial}] ` :
            tagData.text}</span>;
        const items = [itemChildren, li.props.children[1]];
        return React.cloneElement(li, li.props, [items]);
    }

    function itemRender(li, itemProps) {
        const itemChildren = <div>{itemProps.dataItem.device === null ?
            itemProps.dataItem.location_name :
            `${itemProps.dataItem.device.name}[${itemProps.dataItem.device.serial}]`} </div>;
        return React.cloneElement(li, li.props, itemChildren);
    }
    return inEdit ? (
        <td>
            <MultiSelect
                name='marker_set'
                data={markers}
                value={props.dataItem.marker_set}
                textField='location_name'
                dataItemKey='id'
                onChange={(e) => { props.onChange(e, dataItem.id) }}
                itemRender={itemRender}
                filterable={true}
                onFilterChange={handleFilterChange}
                tagRender={tagRenderInEdit}
                style={{ height: 'auto' }}
            />
        </td>)
        : <td>
            <MultiSelect
                data={props.dataItem.marker_set}
                value={props.dataItem.marker_set}
                textField='location_name'
                dataItemKey='id'
                itemRender={itemRender}
                onChange={null}
                tagRender={tagRender}
                style={{ height: 'auto' }}
            />
        </td>
}

function FilterMarkers(props) {
    const intl = useIntl();
    const [markers, setMarkers] = useState([]);
    const [color, setColor] = useState([]);
    const colorsList = ['red', 'blue', 'green', 'yellow', 'gray'];
    const [loading, setLoading] = useState(false);
    const [loggedin, setLoggedin] = useState();
    const [sort, setSort] = useState([{ field: 'serial', dir: 'asc' }]);
    const [filter, setFilter] = useState([]);
    const [farmGridData, setFarmGridData] = useState([]);
    const editField = 'inEdit';
    const cellWidth = '300px';

    function updateFarms(state, dataItem) {
        let url = `${BASEURI}/api/dashboard/farm/${state === 'POST' ? '' : dataItem.id}`,
            options = {
                method: state,
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(dataItem)
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    setLoggedin(false);
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;


                fetch(url, options)
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error(response.statusText, response.status);
                        }
                        if (response.status !== 204)
                            return response.json();
                    })
                    .then(() => {
                        setLoading(false);
                        props.loadFarms();
                    })
                    .catch(error => {
                        // error.then(text => console.log(text));
                        console.error('Error:', error);
                        setLoading(false);
                    });
            });
    }
    function addNew() {
        const dataItem = { inEdit: true, marker_set: [] };
        setFarmGridData([dataItem, ...farmGridData]);
    }
    function remove(dataItem) {
        updateFarms('DELETE', dataItem);
    }
    function add(dataItem) {
        updateFarms('POST', dataItem);
    }
    function update(dataItem) {
        updateFarms('PUT', dataItem);
    }
    function cancel(dataItem) {
        const originalItem = props.farms.find(farm => farm.id === dataItem.id);

        const data = farmGridData.map(farm => farm.id === originalItem.id ?
            originalItem : farm);
        setFarmGridData(data);
    }
    function discard(dataItem) {
        const data = [...farmGridData];
        data.splice(0, 1)
        setFarmGridData(data);
    }
    function handleMarkersChange(event, id) {
        const editedItemID = id;
        const data = farmGridData.map(item => item.id === editedItemID ?
            { ...item, [event.target.name]: event.target.value } : item);
        setFarmGridData(data);
    }
    const Commands = props => (
        <CommandCell
            {...props}
            edit={enterEdit}
            remove={remove}
            add={add}
            discard={discard}
            update={update}
            cancel={cancel}
            editField={editField}
        />

    )
    let newMarkers = props.markersList;
    const Marker = props => {
        return (
            <MarkerCell
                {...props}
                markersList={newMarkers}
                editField={editField}
                onChange={handleMarkersChange}

            />)
    };

    const enterEdit = (dataItem) => {
        setFarmGridData(farmGridData.map(item =>
            item.id === dataItem.id ?
                { ...item, inEdit: true } : item
        )
        )
    }

    useEffect(() => { setFarmGridData(props.farms) }, [props.farms]);

    function onItemChange(event) {
        const editedItemID = event.dataItem.id;
        const data = farmGridData.map(item => item.id === editedItemID ?
            { ...item, [event.field]: event.value } : item);
        setFarmGridData(data);
    }


    return (
        <Modal>
            <Dialog title={intl.formatMessage({ id: 'app.admin.filterMarker.farms', defaultMessage: "Farms" })} onClose={props.onClose}>
                <KGrid
                    scrollable={'scrollable'}
                    style={{ maxHeight: '100%', minHeight: '400px' }}
                    data={filterBy(orderBy(farmGridData, sort), filter)}
                    sortable
                    sort={sort}
                    onSortChange={e => setSort(e.sort)}
                    filterable
                    filter={filter}
                    onFilterChange={e => setFilter(e.filter)}
                    editField="inEdit"
                    onItemChange={onItemChange}
                >
                    <GridToolbar>

                        <button
                            title="Add new"
                            className="k-button k-primary"
                            onClick={addNew}
                        >
                            {intl.formatMessage({ id: 'app.admin.filterMarker.addNew', defaultMessage: "Add new" })}
                        </button>

                    </GridToolbar>
                    <Column title={intl.formatMessage({ id: 'app.admin.filterMarker.name', defaultMessage: "Name" })} width={cellWidth} field='name' />
                    <Column title={intl.formatMessage({ id: 'app.admin.filterMarker.stations', defaultMessage: "Stations" })} width={cellWidth} filterable={false} sortable={false} cell={Marker} />
                    <Column cell={Commands} filterable={false} sortable={false} width={cellWidth} />

                </KGrid>

                <DialogActionsBar>
                    <button className="k-button" onClick={props.onClose}>
                        {intl.formatMessage({ id: 'app.admin.filterMarker.done', defaultMessage: "DONE" })}
                    </button>
                </DialogActionsBar>
            </Dialog>
        </Modal>
    )
}
export default FilterMarkers;