import React, {useState, useEffect} from 'react';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Input, Switch} from '@progress/kendo-react-inputs';
import {getAuthToken, BASEURI} from '../utils';

function ForecastForm(props){

    const [color, setColor] = useState('#000000');
    const [loggedin, setLoggedin] = useState();
    
    function valueRender(element, value){
        if (!value) {
            return element;
        }
        const children = [
            <span>{ value.device === null ? value.location_name : value.device.name}</span>
        ];
        return React.cloneElement(element, { ...element.props }, children);
    }
    function itemRender (li, itemProps) {
        const itemChildren = <div>{itemProps.dataItem.device === null ? itemProps.dataItem.location_name : `${itemProps.dataItem.device.name}[${itemProps.dataItem.device.serial}]` } </div>;
		return React.cloneElement(li, li.props, itemChildren);
    }

    const graphTypes = {
		line: {text:'Line', value:'line'},
		column: {text:'Bar', value:'column'}
	}

	const resampleFnValues = {
		sum: {text:props.intl.formatMessage({id:'app.chart.etcRawForm.sum', defaultMessage:'Sum'}), value:'sum'},
		avg: {text:props.intl.formatMessage({id:'app.chart.etcRawForm.Mean', defaultMessage:'Mean'}), value:'avg'},
		min: {text:props.intl.formatMessage({id:'app.chart.etcRawForm.min', defaultMessage:'Min'}), value:'min'},
		max: {text:props.intl.formatMessage({id:'app.chart.etcRawForm.max', defaultMessage:'Max'}), value:'max'},
		diff: {text:props.intl.formatMessage({id:'app.chart.etcRawForm.diff', defaultMessage:'Diff'}), value:'diff'}
	}

	const resampleValues = {
		null:{text: props.intl.formatMessage({id:'app.chart.etcRawForm.doNotResample', defaultMessage:'Do not resample'}), value:null},
		H:{text: props.intl.formatMessage({id:'app.chart.etcRawForm.hourly', defaultMessage:'Hourly'}), value:'H'},
		D:{text: props.intl.formatMessage({id:'app.chart.etcRawForm.daily', defaultMessage:'Daily'}), value:'D'},
		M: {text: props.intl.formatMessage({id:'app.chart.etcRawForm.monthly', defaultMessage:'Monthly'}), value:'M'}
	}

    return(
        <div className='modal-form-inputs-wrap'>
            <div className="modal-form-inputs-wrap-row">
                <DropDownList 
                    key='marker-select'
                    name='location_marker'
                    required={true}
                    label={props.intl.formatMessage({id:'app.chart.forecastForm.markers', defaultMessage:'MARKER(S)'})}
                    data={props.markers}
                    value={props.graph.location_marker}
                    dataItemKey='id'
                    itemRender={itemRender}
                    onChange={props.handleChange}
                    filterable={true}
                    valueRender={valueRender}
                    onFilterChange={props.handleFilterChange}
                    listNoDataRender={ props.loadingMarkers ? props.noData : null }
                    />
                <DropDownList
                    key='variable-select'
                    name='weather_variable'
                    required={true}
                    label={props.intl.formatMessage({id:'app.chart.forecastForm.weatherVariables', defaultMessage:'WEATHER VARIABLES'})}
                    data={props.weatherVariables}
                    value={props.graph.weather_variable}
                    listNoDataRender={ props.loadingWeatherVariables ? props.noData : null }
                    onChange={props.handleChange}/>
            </div>
            <div className="modal-form-inputs-wrap-row">
            <Input
                    // required={true}
                    name='formula'
                    label={props.intl.formatMessage({id:'app.chart.etcRawForm.formula', defaultMessage:'FORMULA'})}
                    value={props.graph.variables.formula}
                    onChange={props.handleChange}/>
                <DropDownList
                    label={props.intl.formatMessage({id:'app.chart.etcRawForm.resampleFreq', defaultMessage:'RESAMPLE FREQ'})} 
                    name='resample'
                    data={[
                        {text: props.intl.formatMessage({id:'app.chart.etcRawForm.doNotResample', defaultMessage:'Do not resample'}), value:null},
                        {text: props.intl.formatMessage({id:'app.chart.etcRawForm.hourly', defaultMessage:'Hourly'}), value:'H'},
                        {text: props.intl.formatMessage({id:'app.chart.etcRawForm.daily', defaultMessage:'Daily'}), value:'D'},
                        {text: props.intl.formatMessage({id:'app.chart.etcRawForm.monthly', defaultMessage:'Monthly'}), value:'M'}
                    ]}
                    textField='text'
                    dataItemKey='value'
                    value={resampleValues[props.graph.resample]}
                    onChange={props.handleChange}/>
            </div>
            <div className="modal-form-inputs-wrap-row">
                <DropDownList
                    label={props.intl.formatMessage({id:'app.chart.etcRawForm.resampleFn', defaultMessage:'RESAMPLE FN'})} 
                    name='resample_fn'
                    data={[
                        {text:props.intl.formatMessage({id:'app.chart.etcRawForm.sum', defaultMessage:'Sum'}), value:'sum'},
                        {text:props.intl.formatMessage({id:'app.chart.etcRawForm.Mean', defaultMessage:'Mean'}), value:'avg'},
                        {text:props.intl.formatMessage({id:'app.chart.etcRawForm.min', defaultMessage:'Min'}), value:'min'},
                        {text:props.intl.formatMessage({id:'app.chart.etcRawForm.max', defaultMessage:'Max'}), value:'max'},
                        {text:props.intl.formatMessage({id:'app.chart.etcRawForm.diff', defaultMessage:'Diff'}), value:'diff'}
                    ]}
                    textField='text'
                    dataItemKey='value'
                    value={resampleFnValues[props.graph.resample_fn]}
                    onChange={props.handleChange}/>
                <DropDownList
                    label={props.intl.formatMessage({id:'app.chart.rawSensorForm.chartType', defaultMessage:'CHART TYPE'})}
                    name='_type'
                    data={[{text:'Line', value:'line'}, {text:'Bar', value:'column'}]}
                    textField='text'
                    dataItemKey='value'
                    value={graphTypes[props.graph._type]}
                    onChange={props.handleChange}/>
            </div>
            <div className="modal-form-inputs-wrap-row">
                <Input
                    type='color'
                    label={props.intl.formatMessage({id:'app.chart.rawSensorForm.color', defaultMessage:'COLOR'})}
                    name='color'
                    data={color}
                    value={props.graph.color}
                    onChange={props.handleChange}/>
                <Input
                    required={true}
                    name='label'
                    label={props.intl.formatMessage({id:'app.chart.rawSensorForm.label', defaultMessage:'LABEL'})}
                    value={props.graph.label}
                    onChange={props.handleChange}/>
            </div>
            <div className="modal-form-inputs-wrap-row">
                <Input
                    name='axis_min'
                    label={props.intl.formatMessage({id:'app.chart.rawSensorForm.axisMin', defaultMessage:'AXIS MIN'})}
                    value={props.graph.axis_min !== null ? props.graph.axis_min : ''}
                    // value={''}
                    onChange={props.handleChange}/>
                <Input
                    name='axis_max'
                    label={props.intl.formatMessage({id:'app.chart.rawSensorForm.axisMax', defaultMessage:'AXIS MAX'})}
                    value={props.graph.axis_max !== null ? props.graph.axis_max : ''}
                    // value={''}
                    onChange={props.handleChange}/>
            </div>
            <div className="modal-form-inputs-wrap-row">
                <Input
                    required={true}
                    name='y_axis_legend'
                    label={props.intl.formatMessage({id:'app.chart.rawSensorForm.yAxisLegend', defaultMessage:'Y AXIS LEGEND'})}
                    value={props.graph.y_axis_legend}
                    // value={''}
                    onChange={props.handleChange}/>
                <div style={{marginTop: '20px', color:'gray'}}>
                    <span style={{paddingRight:'20px'}}>
                    {props.intl.formatMessage({id:'app.chart.etcRawForm.dataLabels', defaultMessage:'DATA LABELS'})}
                    </span>
                    <Switch 
                        name='data_labels'
                        checked={props.graph.data_labels}
                        onChange={props.handleChange}
                    />
                </div>
            </div>
        </div>
    

    )
}
export default ForecastForm;