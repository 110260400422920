import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import languages from '../lang/languages.json';
import ViewAlertEvents from '../alerts/AlertEvents';
import logo from '../logo.png';
import agscoutLogo from '../images/agscout_small.png'


export class RefreshPortal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    const refreshContainer = document.getElementById("refresh-container");
    refreshContainer.appendChild(this.el);
  }

  componentWillUnmount() {
    const refreshContainer = document.getElementById("refresh-container");
    refreshContainer.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.el
    );
  }
}

function LocaleView() {
  return
}
class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'AgViewer',
    };
  }

  render() {


    return (

      <div
        className="mdl-layout mdl-js-layout mdl-layout--fixed-header"
      >

        <header className="mdl-layout__header">

          <div className="mdl-layout-icon">kk</div>

          <div className="mdl-layout__header-row">

            <span className="mdl-layout-title">
              <img className="ag_logo" src={logo} alt="AGVIEWER"></img>
            </span>
            <div className="mdl-layout-spacer">

            </div>
            <div><span style={{ marginRight: "20px", fontFamily: "inherit" }}><FormattedMessage id='app.layout' defaultMessage='Welcome' />, {this.props.userName}</span></div>
            <ViewAlertEvents />
            <div id="refresh-container"></div>
            <div id='header-locale'>
              <DropDownList
                style={{ color: 'white', width: '100px', border: 'none', marginRight: '-30px' }}
                textField='locale'
                dataItemKey="localeId"
                data={languages}
                value={this.props.locale}
                onChange={this.props.handleLocaleChange}
              />
            </div>
          </div>
          {/* 
              <div className="mdl-layout__tab-bar mdl-js-ripple-effect">
                {this.props.tabs}
                <a 
                  href="#dummy"
                  className="add-tab-button"
                  onClick={(e) => {this.props.addTab(e)}}

                >
                  <i className="material-icons ">add</i>
                </a>
                
              </div> */}

        </header>

        <div className="mdl-layout__drawer">
          <span className="mdl-layout-title">
            <img className="ag_logo" src={logo} alt="AGVIEWER"></img>
            {/* AGVIEWER */}
          </span>
          <nav className="mdl-navigation">
            {/* <a 
                className="mdl-navigation__link"
                onClick={(e) => {this.props.onClick(e, 'profile')}}
              href='/profile/'>
                Profile
              </a> */}
            {/* <a  
                className="mdl-navigation__link"
                onClick={(e) => {this.props.onClick(e, 'settings')}}
              href='/profile/'>
                Settings
              </a> */}

            <div id='nav-locale'>
              <DropDownList
                style={{ color: 'white', width: '100px', border: 'none' }}
                textField='locale'
                dataItemKey="localeId"
                data={languages}
                value={this.props.locale}
                onChange={this.props.handleLocaleChange}
              />
            </div>
            <a
              className="mdl-navigation__link"
              onClick={(e) => { this.props.onClick(e, 'dashboard') }}
              href='/dashboard/'>
              <i className="material-icons">dashboard</i>   <FormattedMessage id='app.dash.nav' defaultMessage='Dashboards' />
            </a>
          </nav>
          <div className="ag-dashboards-ctn">
            {this.props.tabList.map(tab => {
              let activeClass = this.props.activeTab === tab.id ? "is-active" : "";
              return (
                <div key={tab.id} className={"dashboard-item " + activeClass}>
                  <a
                    className={activeClass}
                    onClick={(e) => { this.props.changeTab(e, tab.id) }}
                    href={'#' + tab.id}>
                    <span className={activeClass} key={tab.id}>
                      {tab.name}
                    </span>
                  </a>
                  {
                    !this.props.isLockedUser &&
                    <i
                      className="material-icons"
                      style={{ fontSize: '1.3em' }}
                      onClick={(e) => this.props.open(e, 'edit', tab.id)}
                    >
                      more_vert
                    </i>
                  }

                </div>
              )

            })}

          </div>
          <nav className="mdl-navigation">
            {
              !this.props.isLockedUser &&
              <>
                <a
                  className="mdl-navigation__link"
                  onClick={(e) => { this.props.onClick(e, 'stations') }}
                  href='/stations/'>
                  <i className="material-icons">router</i>  <FormattedMessage id='app.layout.station' defaultMessage='Stations' />
                </a>
                <a
                  className="mdl-navigation__link"
                  onClick={(e) => { this.props.onClick(e, 'alerts') }}
                  href='/alerts/'>
                  <i className="material-icons">warning</i>  <FormattedMessage id='app.layout.alert' defaultMessage='Alerts' />
                </a>
                <a
                  className="mdl-navigation__link"
                  onClick={(e) => { this.props.onClick(e, 'admin') }}
                  href='/admin/'>
                  <i className="material-icons">pie_chart</i> <FormattedMessage id='app.layout.report' defaultMessage='Reports' />
                </a>
                <a
                  className="mdl-navigation__link"
                  onClick={(e) => { this.props.onClick(e, 'schedule') }}
                  href='/schedule/'>
                  <i className="material-icons">schedule</i> <FormattedMessage id='app.layout.schedule' defaultMessage='Irrigation' />
                </a>

                <a className="mdl-navigation__link"
                  href='/agscout/'
                  onClick={(e) => { this.props.onClick(e, 'agscout') }}>

                  <img
                    src={agscoutLogo}
                    alt='AgScout Logo'
                    width='50px'
                    style={{
                      display: 'inline-block',
                      marginLeft: '-3px',
                      marginRight: '9px',
                      width: '31px'
                    }}
                  />
                  <FormattedMessage id='app.layout.AgScout' defaultMessage='AgScout' />
                </a>
                <a
                  className="mdl-navigation__link"
                  onClick={(e) => { this.props.onClick(e, 'dga') }}
                  href='/dga/'>
                  <i className="material-icons">cloud_upload</i>   <FormattedMessage id='app.layout.DGA' defaultMessage='DGA ' />
                </a>
              </>
            }
            {
              (this.props.isAdmin || this.props.isOrganization) && !this.props.isLockedUser &&
              <a
                className="mdl-navigation__link"
                onClick={(e) => { this.props.onClick(e, 'copy') }}
                href='/copy/'>
                <i className="material-icons">build_circle</i>    <FormattedMessage id='app.layout.tools' defaultMessage='Tools ' />
              </a>
            }
            <a
              className="mdl-navigation__link"
              onClick={(e) => { this.props.onClick(e, 'billing') }}
              href='/dga/'>
              <i className="material-icons">credit_card</i>     <FormattedMessage id='app.layout.billing' defaultMessage='Billing ' />
            </a>
          </nav>
          <div className="ag-logout-ctn">
            <a
              className="mdl-navigation__link ag-logout"
              onClick={(e) => { this.props.logout(e) }}
              href="#dummy"
            >
              <i className="material-icons">exit_to_app</i>      <FormattedMessage id='app.layout.logout' defaultMessage='Logout ' />
            </a>
          </div>
        </div>

        <main className="mdl-layout__content">
          {/*<div className"stat-ctn">
              stats go here
            </div> */}
          {/*<div className"chart-ctn">
              charts go here
            </div> */}
          {this.props.children}

        </main>
      </div>


    );
  }
}


export default Layout;