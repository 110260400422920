import React, { Component } from 'react';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Input, Switch, NumericTextBox } from '@progress/kendo-react-inputs';
import { getAuthToken, BASEURI } from '../utils';

class PAWForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			graphName: '',
			colors: '#000000',
			sensors: [],
			extracts: [],
			extract: null,
			'fc': null,
			'wp': null,
			paw_guides: false,
			pawGuides: null
		};

		this.setInitialValues = this.setInitialValues.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.itemRender = this.itemRender.bind(this);
		this.tagRender = this.tagRender.bind(this);
		this.sensorTagRender = this.sensorTagRender.bind(this);
	}

	setInitialValues() {
		this.setState(this.props.initialValues);
		this.setState(this.props.options);
	}

	handleChange(event) {
		this.setState({
			[event.target.name]: event.target.name === 'graphType' || event.target.name === 'paw_guides' ? event.target.value.value : event.target.value,
			pawGuides: event.target.name === 'paw_guides' ? event.target.value : this.setState.pawGuides
		});

		if (event.target.name === 'sensors') {
			if (event.target.value.length > 0) {

				let url = `${BASEURI}/api/extracts/${event.target.value[0].id}`,

					options = {
						method: 'GET',
						headers: {
							"Content-Type": "application/json; charset=utf-8",
						}
					};

				getAuthToken()
					.then(token => token)
					.catch(token => token)
					.then(token => {

						options.headers.Authorization = `Bearer  ${token}`;

						fetch(url, options)
							.then((response) => response.json())
							.then((extracts) => {
								this.setState({
									extracts: extracts
								});
							})
							.catch(error => console.log('Error:', error));
					});

			}
		}
	}

	itemRender(li, itemProps) {
		const itemChildren = <div> {li.props.children} <div style={{ color: "#00F" }}> {itemProps.dataItem.device.name} [{itemProps.dataItem.port}]</div> </div>;
		return React.cloneElement(li, li.props, itemChildren);
	}

	extractItemRender(li, itemProps) {
		const itemChildren =
			<div>
				{itemProps.dataItem.description} [{itemProps.dataItem.unit && itemProps.dataItem.unit}]
				<div style={{ color: "#00F" }}>{itemProps.dataItem.sensor.name} [{itemProps.dataItem.sensor.port}]</div>
			</div>;
		return React.cloneElement(li, li.props, itemChildren);
	}

	tagRender(tagData, li) {
		return React.cloneElement(li, li.props, [
			<span key={tagData.data[0].id} className='custom-tag'>
				<span style={{ display: 'flex', flexFlow: 'row wrap', gap: '3px' }}>
					{tagData.data[0].description} [{tagData.data[0].sensor.port}]
				</span>
			</span>,
		]);
	}

	sensorTagRender(tagData, li) {
		return React.cloneElement(li, li.props, [
			<span key={tagData.data[0].id} className="custom-tag">
				<span style={{ display: 'flex', flexFlow: 'row wrap', gap: '3px' }}>
					{tagData.data[0].name} [{tagData.data[0].port}]
				</span>
			</span>,
		]);
	}

	graphTypes = {
		line: { text: 'Line', value: 'line' },
		column: { text: 'Bar', value: 'column' }
	}

	render() {

		return (
			<div className='modal-form-inputs-wrap'>
				<div className="modal-form-inputs-wrap-row">
					<MultiSelect key='sensor-select'
						name='sensors'
						listNoDataRender={this.props.loadingSensors ? this.props.noData : null}
						required={true}
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.sensor', defaultMessage: 'SENSOR(S)' })}
						data={this.props.sensors}
						value={this.props.graph.sensors}
						textField='name'
						dataItemKey='id'
						itemRender={this.itemRender}
						tagRender={this.sensorTagRender}
						onChange={this.props.handleChange}
						filterable={true}
						onFilterChange={this.props.handleFilterChange}
						style={{ height: 'auto' }} />
					<MultiSelect
						required={true}
						key='extract-select'
						name='extract'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.sensorExtract', defaultMessage: 'SENSOR EXTRACT' })}
						data={this.props.extracts}
						value={this.props.graph.extract}
						textField='description'
						itemRender={this.extractItemRender}
						tagRender={this.tagRender}
						dataItemKey='id'
						onChange={this.props.handleChange}
						filterable={true}
						onFilterChange={this.props.handleExtractFilterChange}
						style={{ height: 'auto' }} />
				</div>
				{/* <div className="modal-form-inputs-wrap-row">
					<Input
						name='fc'
						required={!this.props.graph.predict_fc}
						key='fc-input'
						label={this.props.intl.formatMessage({id:'app.chart.pawForm.fieldCapacity', defaultMessage:'FIELD CAPACITY'})}
						value={this.props.graph.variables.fc === null ? '':this.props.graph.variables.fc}
						onChange={this.props.handleChange}/>
					<Input
						name='wp'
						required={!this.props.graph.predict_fc}
						key='wp-input'
						label={this.props.intl.formatMessage({id:'app.chart.pawForm.wiltingPoint', defaultMessage:'WILTING POINT'})}
						value={this.props.graph.variables.wp === null ? '':this.props.graph.variables.wp}
						onChange={this.props.handleChange}/>
				</div> */}
				<div className="modal-form-inputs-wrap-row">
					{this.props.graph.sensors.map((sensor) => {
						return (
							<React.Fragment>
								<Input
									title={this.props.intl.formatMessage({ id: 'app.chart.pawForm.fieldCapacity', defaultMessage: 'FIELD CAPACITY' })}
									name={sensor.id + '.fc'}
									required={!this.props.graph.predict_fc}
									key='fc-input'
									label={'FC' + ` (${sensor.name} [${sensor.port}])`}
									value={this.props.graph.variables.paw_variables[sensor.id].fc}
									onChange={this.props.handleChange} />
								<Input
									title={this.props.intl.formatMessage({ id: 'app.chart.pawForm.wiltingPoint', defaultMessage: 'WILTING POINT' })}
									name={sensor.id + '.wp'}
									required={!this.props.graph.predict_fc}
									key='wp-input'
									label={'WP' + ` (${sensor.name} [${sensor.port}])`}
									value={this.props.graph.variables.paw_variables[sensor.id].wp}
									onChange={this.props.handleChange} />
							</React.Fragment>)
					})}
				</div>
				<div className="modal-form-inputs-wrap-row">
					<div style={{ marginTop: '20px', color: 'gray' }}>
						<span style={{ paddingRight: '20px' }}>
							{this.props.intl.formatMessage({ id: 'app.chart.pawForm.predictFc', defaultMessage: 'PREDICT FC' })}
						</span>
						<Switch
							name='predict_fc'
							checked={this.props.graph.predict_fc}
							onChange={this.props.handleChange}
						/>
					</div>
				</div>
				<div className="modal-form-inputs-wrap-row">
					<NumericTextBox
						name='depth'
						required={!this.props.graph.predict_fc}
						key='depth'
						label={this.props.intl.formatMessage({ id: 'app.chart.pawForm.sensorDepth', defaultMessage: 'SENSOR DEPTH' })}
						value={this.props.graph.variables.depth === null ? '' : this.props.graph.variables.depth}
						onChange={this.props.handleChange} />

					<NumericTextBox
						name='rock_percent'
						required={!this.props.graph.predict_fc}
						key='rock_percent'
						label={this.props.intl.formatMessage({ id: 'app.chart.pawForm.soilRock', defaultMessage: 'SOIL ROCK (%)' })}
						value={this.props.graph.variables.rock_percent === null ? '' : this.props.graph.variables.rock_percent}
						onChange={this.props.handleChange} />
				</div>
				<div className="modal-form-inputs-wrap-row">
					<NumericTextBox
						label={this.props.intl.formatMessage({ id: 'app.chart.pawForm.irrigatedSoil', defaultMessage: 'IRRIGATED SOIL (%)' })}
						name="irrigated_soil_percent"
						value={this.props.graph.variables['irrigated_soil_percent']}
						step={0}
						spinners={false}
						onChange={this.props.handleChange}
					/>
					<DropDownList
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.chartType', defaultMessage: 'CHART TYPE' })}
						name='_type'
						data={[{ text: 'Line', value: 'line' }, { text: 'Bar', value: 'column' }]}
						textField='text'
						dataItemKey='value'
						value={this.graphTypes[this.props.graph._type]}
						onChange={this.props.handleChange} />
				</div>
				<div className="modal-form-inputs-wrap-row">
					<Input
						type='color'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.color', defaultMessage: 'COLOR' })}
						name='color'
						data={this.state.color}
						value={this.props.graph.color}
						onChange={this.props.handleChange} />
					<Input
						required={true}
						name='label'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.label', defaultMessage: 'LABEL' })}
						value={this.props.graph.label}
						onChange={this.props.handleChange} />
					<div style={{ marginTop: '20px', color: 'gray' }}>
						<span style={{ paddingRight: '20px' }}>
							{this.props.intl.formatMessage({ id: 'app.chart.pawRangeGuides', defaultMessage: 'PAW RANGE GUIDES' })}
						</span>
						<Switch
							name='paw_guides'
							checked={this.props.graph.paw_guides}
							onChange={this.props.handleChange}
						/>
					</div>
				</div>
				{/* <DropDownList
					label="PAW RANGE GUIDES" 
					name='paw_guides'
					data={[{text:'ON', value:true}, {text:'OFF', value:false}]}
					textField='text'
					dataItemKey='value'
					value={this.props.graph.pawGuides}
					onChange={this.props.handleChange}/> */}
				<div className="modal-form-inputs-wrap-row">
					<Input
						name='axis_min'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.axisMin', defaultMessage: 'AXIS MIN' })}
						value={this.props.graph.axis_min !== null ? this.props.graph.axis_min : ''}
						onChange={this.props.handleChange} />
					<Input
						name='axis_max'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.axisMax', defaultMessage: 'AXIS MAX' })}
						value={this.props.graph.axis_max !== null ? this.props.graph.axis_max : ''}
						onChange={this.props.handleChange} />
				</div>
				<div className="modal-form-inputs-wrap-row">
					<Input
						required={true}
						name='y_axis_legend'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.yAxisLegend', defaultMessage: 'Y AXIS LEGEND' })}
						value={this.props.graph.y_axis_legend}
						onChange={this.props.handleChange} />
					<div></div>
				</div>
			</div>
		);
	}
}


export default PAWForm;