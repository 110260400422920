import 'react-app-polyfill/ie9';
import React, { useState, useEffect } from 'react';
import { getAuthToken, BASEURI, cookies } from './utils';
import ReactDOM from 'react-dom';
import {IntlProvider, useIntl} from 'react-intl';  
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import spanish from './lang/es.json';
import english from './lang/en.json';

// Kendo react intl
import {
    IntlProvider as KendoReactIntlProvider,
    load,
    LocalizationProvider,
    loadMessages,
  } from '@progress/kendo-react-intl';

import weekData from 'cldr-core/supplemental/weekData.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import numbers from 'cldr-numbers-full/main/es/numbers.json';
import dateFields from 'cldr-dates-full/main/es/dateFields.json';
import currencies from 'cldr-numbers-full/main/es/currencies.json';
import caGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import timeZoneNames from 'cldr-dates-full/main/es/timeZoneNames.json';

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
  );
  loadMessages(spanish, 'es');


function Provide(props){
    const [isLocaleLoaded, setIsLocaleLoaded] = useState(false);
    const [client, setClient] = useState({});
    const [messages, setMessages] = useState(english);
    const [locale, setLocale] = useState({
                    "locale": "English",
                    "localeId": "en"
                    });

    useEffect(()=>{
        loadLocaleFromCookies();
        loadLocale();
    },[])

    function addLocaleToCookies(lan){
        const date = new Date();
        const daysToExpire = 30;
        date.setTime(date.getTime()+(daysToExpire*24*60*60*1000));
        cookies.setItem('locale', lan, date);
    }

    function loadLocaleFromCookies(){
        const locale = cookies.getItem('locale');
        setLocaleData(locale);
    }

    function saveLocale(localeItem){
        
        let url = BASEURI + `/api/dashboard/client/${client.id}/`,
        options = {
            method:'put',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body:JSON.stringify({
                lang:localeItem.localeId
            }),
        };
        getAuthToken()
        .then(token => token)
        .catch(token => token)
        .then(token => {

            //no token exists or all tokens are expired
            if (token === false){
                // set the default locale to english
                return false;
            }

            options.headers.Authorization = `Bearer  ${token}`;

            fetch(url, options)
            .then((response) => response.json())
            .then((client) => {
            })
            .catch(error => {
                console.error('Error:', error.code);  
            });
        });

    }
    function loadLocale(){

        let url = BASEURI + '/api/dashboard/superuser/',
        options = {
            method:'GET',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        };
        getAuthToken()
        .then(token => token)
        .catch(token => token)
        .then(token => {

            //no token exists or all tokens are expired
            if (token === false){
                return false;
            }

            options.headers.Authorization = `Bearer  ${token}`;

            fetch(url, options)
            .then((response) => response.json())
            .then((client) => {
                // console.log('the agents', client.user.id)
                setClient(client);
                setLocaleData(client.lang);
                addLocaleToCookies(client.lang);
                setIsLocaleLoaded(true);
            })
            .catch(error => {
                console.error('Error:', error.code);  
            });
        });
    }

    function setLocaleData(lang){
        if (lang){
            if (lang === 'en'){
                setMessages(english);
                setLocale({
                    "locale": "English",
                    "localeId": "en"
                    });

            }else if(lang === 'es'){
                setMessages(spanish);
                setLocale({
                    "locale": "Español",
                    "localeId": "es"
                   });
            }
        }
    }

    function handleLocaleChange(e){
        const lan = e.value;

        addLocaleToCookies(lan.localeId);
        saveLocale(lan);

        if (lan.localeId === 'es'){
                setLocale(lan);
                setMessages(spanish);
        }else if(lan.localeId === 'en'){
                setLocale(lan);
                setMessages(english);
        }
    }
    return (
        <LocalizationProvider language={locale.localeId}>
            <KendoReactIntlProvider locale={locale.localeId}>
                <IntlProvider locale={locale.localeId} messages={messages}>
                    <ReactIntl
                        handleLocaleChange={handleLocaleChange}
                        locale={locale}/>
                </IntlProvider>
            </KendoReactIntlProvider>
        </LocalizationProvider>)
}

function ReactIntl(props){
    const intl = useIntl();
    
    return(<App
        handleLocaleChange={props.handleLocaleChange}
        locale={props.locale} 
        intl={intl}/>)
}


ReactDOM.render( <Provide />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
