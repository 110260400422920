// React
import React, { useEffect, useRef, useState } from 'react'

// Third party
import { Wrapper } from '@googlemaps/react-wrapper'
import {MarkerClusterer} from '@googlemaps/markerclusterer'
import { CustomRenderer } from './googlemaps'

// Project
import { SCOUTURI, SCOUTAPIKEY} from '../../utils'
import MapVisuals from './stats/MapVisuals'
import Spinner from '../../general/Spinner'
import { buildParamStr } from '../utils'
import colors from './colors'

function getCoordinates(plot){
    return {lng: parseFloat(plot.lon), lat: parseFloat(plot.lat)}
}


const mapStyle = [
    {
      featureType: "all",
      elementType: "labels",
      stylers: [
        { visibility: "off" }
      ]
    }
  ]

function createMarker(marker, ranges){
    const coord = getCoordinates(marker)

    const color = getMarkerColor(marker, ranges)

    const svg = window.btoa(`
        <svg fill="${color}"
            stroke="${color && color.toLowerCase() === '#ffffff' ? '#000000': color}"
            stroke-width='2'
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 120 120">
                <circle cx="60" cy="60" opacity=".6" r="28" />
        </svg>`)

    return new window.google.maps.Marker({
        position: coord,
        icon: {
            url: `data:image/svg+xml;base64,${svg}`,
            size: new window.google.maps.Size(25,25),
            scaledSize: new window.google.maps.Size(25,25),
            // anchor: new window.google.maps.Point(15,30),
        },
        // icon: symbol,
        // map: map,
        color: color,
        id: marker.id,
        row_number: marker.row_number,
        plant_number: marker.plant_number,
        number_of_counts: marker.number_of_counts,
        draggable: false
    })
}


function getMarkerColor(marker, ranges){

    // const colors = [
    //     '#de6e56',
    //     '#e2e2e2',
    //     '#a7d5ed',
    //     '#63bff0',
    //     '#22a7f0'
    //     // '#ffffff',
    //     // Lime
    //     // '#d9f99d',
    //     // '#a3e635',
    //     // '#65a30d',
    //     // '#3f6212'
    //     // Red
    //     // '#fecaca',
    //     // '#f87171',
    //     // '#dc2626',
    //     // '#991b1b'
    // ]

    for (let i = 0; i < ranges.length; i++)
    if (marker.number_of_counts <= ranges[i+1])
    return colors[i]
}


function getInfoWindowContent(marker){
    return `<div>
                Fila Número:
                <span class="text-slate-700 font-medium"> 
                    ${marker.row_number}
                </span>
            </div>
            <div>
                Planta Número:
                <span class="text-slate-700 font-medium"> 
                    ${marker.plant_number}
                </span>
            </div>
            <div>
                Conteo:
                <span class="text-slate-700 font-medium"> 
                    ${marker.number_of_counts}
                </span>
            </div>`;
}


function attachListener(marker, map){
    let infowindow;
    marker.addListener('mouseover', () => {
        infowindow = new window.google.maps.InfoWindow({
            content: getInfoWindowContent(marker),
          });
        infowindow.open({
            anchor: marker, 
            map
        })
    })
    marker.addListener('click', () => {
        infowindow = new window.google.maps.InfoWindow({
            content: getInfoWindowContent(marker),
          });
        infowindow.open({
            anchor: marker, 
            map
        })
    })
    marker.addListener('mouseout', () => {
        infowindow.close()
    })
}

function Body(props){

    const [mapObject, setMapObject] = useState()
    // const [clusterer, setClusterer] = useState(null)
    const [markerObjArr, setMarkerObjArr] = useState([])
    const ref = useRef();

    useEffect(() => {
        if (ref.current && !mapObject){
            const map = new window.google.maps.Map(ref.current, 
                {
                    center: {lat: -33.447487, lng: -70.673676},
                    zoom: 3,
                    scrollwheel: false,
                    mapTypeId: 'hybrid',
                    gestureHandling: 'greedy',
                    credits: false
                })
            map.set('styles', mapStyle)
            setMapObject(map)
        }
    }, [ref.current, mapObject])

    // Render scout markers
    useEffect(() => {

        // if(!mapScriptLoaded || window.google === undefined)
        if (!mapObject || !props.ranges)
        return

        // if (!props.markers || props.markers.length < 1)
        // return

        // Remove all existing markers
        for (let marker of markerObjArr)
        marker.setMap(null)

        let newMarkers = []
        // if (clusterer){
        //     clusterer.clearMarkers()
        //     clusterer.reset()
        // }

        const bounds  = new window.google.maps.LatLngBounds()
        // Create new markers
        for (let i in props.markers){
            let m = createMarker(props.markers[i], props.ranges);
            attachListener(m, mapObject)
            m.setMap(mapObject);
            newMarkers.push(m)
            bounds.extend(m.position)
        }
        
        // setClusterer(new MarkerClusterer({map: mapObject, markers: newMarkers, renderer: new CustomRenderer}))
        
        if (newMarkers.length > 0){
            mapObject.fitBounds(bounds, 100)
            mapObject.panToBounds(bounds)
        }

        setMarkerObjArr(newMarkers)

    }, [props.markers, mapObject])


    function render(status){
        return <div className="flex justify-center items-center"><h1>{status}</h1></div>
    }

    return <div className="flex flex-row h-full">
            <div className="w-full h-full relative">
                {props.children}
                <Spinner show={props.showSpinner} backDrop={false}/>
                <Wrapper apiKey='AIzaSyD6QFn_rnwHupKdG4-iFnLNZsvhBQ0jw_M' render={render}>
                    <div ref={ref} id="scout_map" className="h-full"></div>
                </Wrapper>
            </div>
    </div>
}


function ScoutMap(props){

    const [ranges, setRanges] = useState([])
    const {pageState} = props;

    function getRanges(){

        if (!pageState.scoutType || !pageState.farm)
        return

        let params = buildParamStr({
            year: pageState?.year.value,
            type: pageState.scoutType?.id
        })
        
        if (!pageState.farm)
        return
    
        let url = `${SCOUTURI}/api/v1.0/farm/${pageState.farm.id}/quintile/ranges/` + params,
        options = {
            method:'GET',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            }
        };
    
        const APIKEY = SCOUTAPIKEY

        options.headers.Authorization = `Api-Key ${APIKEY}`;
    
        fetch(url, options)
        .then((response) => {
            if(!response.ok){
                throw new Error(response.statusText, response.status);
            }
            return response.json();
        })
        .then((response) => {
            setRanges(response.data)
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    useEffect(() => getRanges(), [props])

    // return <div className="flex flex-col flex-grow md:w-auto bg-white overflow-hidden rounded-md">
    return <div className="col-span-12 row-span-3 md:col-span-8 md:row-span-6 flex flex-col bg-white overflow-hidden rounded-md shadow-lg"
            style={{minHeight: '450px'}}
            >
          <Body markers={props.markers} showSpinner={props.showSpinner} ranges={ranges}>
                <MapVisuals
                    scoutType={pageState.scoutType}
                    farm={pageState.farm}
                    year={pageState?.year?.value}
                    ranges={ranges}
                />
          </Body>
    </div>
}

export default ScoutMap;