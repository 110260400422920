import React, { useEffect, useRef, useState } from 'react'
import CopyChart from './CopyChart'
import CopyDashboard from './CopyDashboard'
import DeleteRecords from './DeleteRecords';
import { BASEURI, getAuthToken } from '../utils';
import { RefreshPortal } from '../general/Layout';
import RefreshButton from '../general/RefreshButton';
import { useIntl } from 'react-intl';

function Tools(props) {
    const [users, setUsers] = useState({
        all: [],
        today: [],
        thisMonth: [],
        inactive: []
    });
    const [notInitial, setNotInitial] = useState(false);
    const [loading, setLoading] = useState(false);
    const intl = useIntl();
    
    useEffect(()=>{
        loadUsers();
        // document.getElementById("refresh").addEventListener('click', refresh);
        // return () => document.getElementById('refresh').removeEventListener('click', refresh);
    },[]);

    const refresh =()=>{ loadUsers(); }
    
    const loadUsers = () => {
        if(notInitial){
            setLoading(true);
        }
        let url = BASEURI + '/api/dashboard/user/',
        options = {
            method:'GET',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        };
        getAuthToken()
        .then(token => token)
        .catch(token => token)
        .then(token => {

            //no token exists or all tokens are expired
            if (token === false){
                this.setState({loggedin:false});
                return false;
            }

            options.headers.Authorization = `Bearer  ${token}`;

            fetch(url, options)
            .then((response) => {
                return response.json();
            })
            .then((allUsers) => {
                !notInitial && setNotInitial(true);
                setLoading(false);

                //multiplier to convert miliseconds to hours
                // divide by 1000 * 60 * 60 * 24
                let divider = 1000 * 60 * 60 * 24;

                let today = allUsers.filter(user => {
                    try{
                        let diffMS = Math.abs(new Date() - new Date(user.user.last_login));
                        return Math.round(diffMS / divider) <= 1;
                    } catch (error){
                        return false;
                    }
                    
                }),
                thisMonth = allUsers.filter(user => {
                    try {
                        let diffMS = Math.abs(new Date() - new Date(user.user.last_login));
                        return Math.round(diffMS / divider) <= 30;
                    }
                    catch (error) {
                        return false;
                    }
                }),
                inactive = allUsers.filter(user => {
                    try {
                        let diffMS = Math.abs(new Date() - new Date(user.user.last_login));
                        return  Math.round(diffMS / divider) > 30;
                    } catch (error) {
                        return true;
                    }
                    
                });

                setUsers({
                    all: allUsers,
                    today: today,
                    thisMonth: thisMonth,
                    inactive: inactive,
                });
            })
            .catch(error => {
                console.error('Error:', error.code);  
            });
        });
    }
    

    const tabsRef = useRef(null);
    useEffect(()=>{
        if (window.componentHandler)
        window.componentHandler.upgradeElement(tabsRef.current);

    },[])

    return (
        <div className="mdl-grid">
        <div className='breadcrumb reports_page-breadcrumb mdl-grid mdl-cell mdl-cell--12-col'>
            {intl.formatMessage({id:'app.tools.tools', defaultMessage:'TOOLS'})}
            </div>
            <RefreshPortal>
                <RefreshButton onClick={refresh} loading={loading} />
            </RefreshPortal>
            <div className="mdl-cell mdl-cell--12-col">
                <div ref={tabsRef} className="mdl-tabs mdl-js-tabs mdl-js-ripple-effect">
                    <div className="mdl-tabs__tab-bar">
                        <a href="#copy-chart-panel" className='mdl-tabs__tab is-active'>
                            {intl.formatMessage({id:'app.tools.copyCharts', defaultMessage:'Copy Charts'})}
                        </a>
                        <a href="#copy-dash-panel" className='mdl-tabs__tab'>
                            {intl.formatMessage({id:'app.tools.copyDash', defaultMessage:'Copy Dashboards'})}
                        </a>
                        <a href="#delete-records" className='mdl-tabs__tab'>
                            {intl.formatMessage({id:'app.tools.deleteRecords', defaultMessage:'Delete Records'})}
                        </a>
                    </div>
                    <div className="mdl-tabs__panel is-active" id="copy-chart-panel">
                        <CopyChart users={users.all}/>
                    </div>
                    <div className="mdl-tabs__panel" id="copy-dash-panel">
                        <CopyDashboard users={users.all} />
                    </div>
                    <div className="mdl-tabs__panel" id="delete-records">
                        <DeleteRecords
                        stations={props.stations}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tools
