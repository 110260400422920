import React, { useEffect, useState } from 'react';
import { getAuthToken, BASEURI } from '../utils';

import { useIntl } from 'react-intl';
import ClickAwayListener from 'react-click-away-listener';

export default function ResampleDropdown(props) {
    const intl = useIntl();
    const [resampleOptions, setResampleOptions] = useState({
        resample: null,
        resample_fn: null
    });

    const resample = {
        'H': intl.formatMessage({ id: 'app.chart.etcRawForm.hourly', defaultMessage: 'Hourly' }),
        'D': intl.formatMessage({ id: 'app.chart.etcRawForm.daily', defaultMessage: 'Daily' }),
        '7D': intl.formatMessage({ id: 'app.chart.etcRawForm.7Day', defaultMessage: '7-Day' }),
        'W': intl.formatMessage({ id: 'app.chart.etcRawForm.weekly', defaultMessage: 'Weekly' }),
        '15D': intl.formatMessage({ id: 'app.chart.etcRawForm.15Day', defaultMessage: '15-Day' }),
        '2W': intl.formatMessage({ id: 'app.chart.etcRawForm.biWeekly', defaultMessage: 'Bi-Weekly' }),
        'M': intl.formatMessage({ id: 'app.chart.etcRawForm.monthly', defaultMessage: 'Monthly' })
    }

    useEffect(() => {
        if (resampleOptions.resample_fn)
            resampleChart();

    }, [resampleOptions.resample_fn])

    function resampleChart() {
        const resampleFunction = resampleOptions.resample_fn;
        const resample = resampleOptions.resample;
        props.setLoading(true);
        props.toggleResampleDropdown()

        let url = `${BASEURI}/api/chart/resample/${props.chartId}/freq/${resample}/fn/${resampleFunction}/`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((graphs_data) => {
                        props.setLoading(false);
                        props.updateGraphs(graphs_data);

                    })
                    .catch(error => console.error('Error:', error));

            });

    }

    function handleChange(name, value) {

        setResampleOptions({ ...resampleOptions, [name]: value });
    }

    return (
        <ClickAwayListener onClickAway={props.toggleResampleDropdown}>
            {resampleOptions.resample ?
                <ul className="absolute right-0 min-w-[150px]  drop-shadow-lg bg-white z-50">
                    <li className="p-2 pl-4 font-medium border-b-[1px] border-b-gray-100">
                        <div className='whitespace-nowrap flex justify-between'>
                            <button className="pl-0 text-emerald-400" onClick={() => { handleChange('resample', null) }}>
                                {resample[resampleOptions.resample]}
                            </button>
                        </div>
                    </li>
                    <li className="p-2  pl-4 hover:bg-gray-100 cursor-pointer" onClick={() => handleChange('resample_fn', 'sum')}>
                        {intl.formatMessage({ id: 'app.chart.etcRawForm.sum', defaultMessage: 'Sum' })}
                    </li>
                    <li className="p-2  pl-4 hover:bg-gray-100 cursor-pointer" onClick={() => handleChange('resample_fn', 'avg')}>
                        {intl.formatMessage({ id: 'app.chart.etcRawForm.Mean', defaultMessage: 'Mean' })}
                    </li>
                    <li className="p-2  pl-4 hover:bg-gray-100 cursor-pointer" onClick={() => handleChange('resample_fn', 'min')}>
                        {intl.formatMessage({ id: 'app.chart.etcRawForm.min', defaultMessage: 'Min' })}
                    </li>
                    <li className="p-2  pl-4 hover:bg-gray-100 cursor-pointer" onClick={() => handleChange('resample_fn', 'max')}>
                        {intl.formatMessage({ id: 'app.chart.etcRawForm.max', defaultMessage: 'Max' })}
                    </li>
                    <li className="p-2  pl-4 hover:bg-gray-100 cursor-pointer" onClick={() => handleChange('resample_fn', 'diff')}>
                        {intl.formatMessage({ id: 'app.chart.etcRawForm.diff', defaultMessage: 'Diff' })}
                    </li>
                </ul> :

                <ul className="absolute right-0 min-w-[150px]  drop-shadow-lg bg-white z-50">
                    <li className="p-2 pl-4 font-medium border-b-[1px] border-b-gray-100">
                        <div className='whitespace-nowrap'>
                            <button className="text-gray-500 pl-0">
                                {intl.formatMessage({ id: 'app.chart.resample', defaultMessage: 'Resample' })}
                            </button>
                        </div>
                    </li>
                    <li className="p-2  pl-4 hover:bg-gray-100 cursor-pointer" onClick={() => handleChange('resample', 'H')}>
                        {intl.formatMessage({ id: 'app.chart.etcRawForm.hourly', defaultMessage: 'Hourly' })}
                    </li>
                    <li className="p-2  pl-4 hover:bg-gray-100 cursor-pointer" onClick={() => handleChange('resample', 'D')}>
                        {intl.formatMessage({ id: 'app.chart.etcRawForm.daily', defaultMessage: 'Daily' })}
                    </li>
                    <li className="p-2  pl-4 hover:bg-gray-100 cursor-pointer" onClick={() => handleChange('resample', '7D')}>
                        {intl.formatMessage({ id: 'app.chart.etcRawForm.7Day', defaultMessage: '7-Day' })}
                    </li>
                    <li className="p-2  pl-4 hover:bg-gray-100 cursor-pointer" onClick={() => handleChange('resample', 'W')}>
                        {intl.formatMessage({ id: 'app.chart.etcRawForm.weekly', defaultMessage: 'Weekly' })}
                    </li>
                    <li className="p-2  pl-4 hover:bg-gray-100 cursor-pointer" onClick={() => handleChange('resample', '15D')}>
                        {intl.formatMessage({ id: 'app.chart.etcRawForm.15Day', defaultMessage: '15-Day' })}
                    </li>
                    <li className="p-2  pl-4 hover:bg-gray-100 cursor-pointer" onClick={() => handleChange('resample', '2W')}>
                        {intl.formatMessage({ id: 'app.chart.etcRawForm.biWeekly', defaultMessage: 'Bi-Weekly' })}
                    </li>
                    <li className="p-2  pl-4 hover:bg-gray-100 cursor-pointer" onClick={() => handleChange('resample', 'M')}>
                        {intl.formatMessage({ id: 'app.chart.etcRawForm.monthly', defaultMessage: 'Monthly' })}
                    </li>
                </ul>}
        </ClickAwayListener>
    )
}