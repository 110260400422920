import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Chart from '../../../charts/Chart';
import { formatDate } from '../../../utils';
import ForecastConfig from './ForecastConfig';


function ForecastCharts(props) {
    const [showConfig, setShowConfig] = useState(false);
    const intl = useIntl()

    function handleConfigurationButton() {
        setShowConfig(!showConfig)
    }

    const charts = props.charts.map((chart) => {
        let lastDates, maxChartDate;
        if (chart.graphs.length > 0) {
            lastDates = chart.graphs.map(
                (graph) => graph.data.length > 0 ? graph.data[graph.data.length - 1][0] : null
            );
            if (lastDates.length > 0)
                maxChartDate = lastDates.reduce((p, v) => p > v ? p : v);
        }
        const chartTitle = `<div class="mdl-layout-spacer">
                                <b>${chart.name}</b>
                                <span style="font-size:.8em; font-weight:900; color:gray;">
                                    ${maxChartDate ? formatDate(new Date(maxChartDate)) : ''}
                                </span>
                            </div>`;
        return (
            <div
                key={chart.id}
                className='mdl-grid mdl-cell mdl-cell--6-col mdl-cell--12-col-phone'
            >
                <Chart
                    intl={intl}
                    graphs={chart.graphs}
                    key={chart.id}
                    name={chart.name}
                    maximized={true}
                    chartId={chart.id}
                    isForecast={true}
                    title={chartTitle}
                />
            </div>
        )
    })

    return <div className="mdl-grid" style={{ justifyContent: 'center' }}>
        <div className="mdl-grid mdl-cell mdl-cell--12-col">
            {
                showConfig &&
                <ForecastConfig onClose={handleConfigurationButton} />
            }
            <div className="mdl-grid mdl-cell mdl-cell--12-col">
                <div className="mdl-cell mdl-cell--6-col"></div>
                <div className="mdl-cell mdl-cell--6-col">
                    <div className="conf-btn-ctn">
                        <div></div>
                        <div className="config-btn" onClick={handleConfigurationButton}>
                            <i className="material-icons">settings</i>
                            {
                                intl.formatMessage(
                                    {
                                        id: 'app.admin.forecast.configurations',
                                        defaultMessage: 'CONFIGURATIONS'
                                    }
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                props.charts.length > 0
                    ?
                    charts
                    :
                    <span className='text-slate-700 text-sm mx-auto'>
                        No ha activado el pronóstico del tiempo para ninguna estación
                    </span>
            }
        </div>
    </div>;
}

export default ForecastCharts;