// React
import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

// Third-party
import Highcharts from 'highcharts/highstock';
import histogram from 'highcharts/modules/histogram-bellcurve';
(histogram)(Highcharts)


function formatter (){
    if (this.value > 1000000) {
        return Highcharts.numberFormat(this.value / 1000, 1) + "M"
    } else if (this.value > 999) {
        return Highcharts.numberFormat(this.value / 1000, 1) + "K";
    } else {
        return this.value
    }
}

export default function ScoutHistogram(props){
    
    const intl = useIntl();
    const ref = useRef(null);

    function initChart(){

        Highcharts.chart(ref.current.id, {
            chart: {
                // marginLeft: 60, // Keep all charts left aligned
                spacingTop: 20,
                spacingBottom: 20,
                height: '200px'
            },
            title: {
                text: null
			},
			plotOptions: {
				histogram: {
                    accessibility: {
                        point: {
                            valueDescriptionFormat: '{index}. {point.x:.3f} to {point.x2:.3f}, {point.y.1f}.'
                        }
                    },
                    tooltip: {
                        pointFormat: `<span style="font-size: 10px">
                                        {point.x:.0f} - {point.x2:.0f}
                                      </span>
                                      <br/>
                                      <span style="color:{point.color}">●</span>
                                      {series.name}
                                      <b>{point.y}</b><br/>`
                    }
				},
                scatter: {
                    tooltip: {
                        // pointFormat: `<span style="font-size: 10px">
                        //                 {point.x:.0f} - {point.x2:.0f}
                        //               </span>
                        //               <br/>
                        //               <span style="color:{point.color}">●</span>
                        //               {series.name}
                        //               <b>{point.y}</b><br/>`
                        pointFormat: `<b>{point.y}</b>`
                    }
				}
			},
            credits: {
                enabled: false
            },
            legend: {
                enabled: false
			},
			xAxis: [
                {
                title: { text: intl.formatMessage({id:'app.agscout.data', defaultMessage:'Data'}) },
                alignTicks: false,
                visible: false
              }, 
              {
                title: { text: intl.formatMessage({id:'app.agscout.countObservation', defaultMessage:'Counts/Observation'}) },
                alignTicks: false,
                opposite: true
              }],
            yAxis: [{
                title: {
                    text: intl.formatMessage({id:'app.agscout.countObservation', defaultMessage:'Counts/Observation'})
                },
                labels: {
                    formatter: formatter
                },
                min: 0,
                max: 1000
              }, {
                title: {
                    text: intl.formatMessage({id:'app.agscout.observationsGroup', defaultMessage:'Observations/Group'})
                },
                opposite: true,
                labels: {
                    formatter: formatter
                }
              }],
            series: [{
                name: 'Observaciónes',
                type: 'histogram',
                binWidth: 50,
                xAxis: 1,
                yAxis: 1,
                baseSeries: 's1',
                zIndex: -1,
                // color: '#23cdfd'
                // color: '#7BE1FD',
                // color: '#C1DAD3',
                color: '#9EE4F8'
            },{
                name: 'Conteos',
                type: 'scatter',
                // visible: false,
                color: '#000',
                data: props.data.map(point => point.number_of_counts),
                id: 's1',
                marker: {
                    radius: 1.5
                }
            }]
        });

    }

    useEffect(() => {
        if (props.data.length > 0 && ref.current)
        initChart()
    }, [props.data, ref.current])

    return <div ref={ref} id={'scouts_historgram'} className="flex-grow">
    </div>
}