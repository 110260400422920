import '@babel/polyfill';
import React, { Component } from 'react';
import './App.css';
import './chart.css';
import '@progress/kendo-theme-material/dist/all.css';
import { FormattedMessage } from 'react-intl';
import Layout from './general/Layout';
import Dashboard from './general/Dashboard';
import Stations from './stations/Stations';
import Settings from './general/Settings';
import Profile from './general/Profile';
import Alerts from './alerts/Alerts';
// import Modal from './Modal';
import Tab from './general/Tab';
import Login from './general/Login';
import { getAuthToken, BASEURI } from './utils';
import TabDialog from './general/TabDialog';
import Modal from './general/Modal';
import Admin from './admin/Admin';
import DGAUploadJobs from './dga/DGAUploadJobs';
import ConfirmDeleteDialog from './general/ConfirmDeleteDialog';
import Tools from './tools/Tools';
import Billing from './billing/Billing';
import IrrigationScheduler from './scheduler/Scheduler';
import ScoutPage from './scout/ScoutPage';


class LoginCtn extends Component {

  render() {
    return (
      <div>
        {this.props.children}
      </div>);
  }
}


class LayoutCtn extends Component {

  render() {
    return <div> {this.props.children} </div>;
  }
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggingIn: false,
      notInitial: false,
      stations: [],
      allStations: [],
      markers: [],
      markersCopy: [],
      weatherVariables: [],
      loadingWeatherVariables: false,
      loadingMarkers: false,
      loading: false,
      page: 'admin',
      currentTab: null,
      tabs: [],
      loggedin: localStorage.getItem('token') ? true : false,
      newTab: {},
      tabDialogAction: 'new',
      showTabDialog: false,
      errorMsg: "",
      currentTabName: null,
      userName: null,
      showDeleteDialog: false,
      isAdmin: false,
      isOrganization: false,
      isLockedUser: false,
    }

    this.navigate = this.navigate.bind(this);
    this.addTab = this.addTab.bind(this);
    this.makeTabCurrent = this.makeTabCurrent.bind(this);
    this.loadTabs = this.loadTabs.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleTabFormChange = this.handleTabFormChange.bind(this);
    this.openTabDialog = this.openTabDialog.bind(this);
    this.closeTabDialog = this.closeTabDialog.bind(this);
    this.deleteTab = this.deleteTab.bind(this);
    this.loadStations = this.loadStations.bind(this);
    this.handleMarkersFilterChange = this.handleMarkersFilterChange.bind(this);
    this.loadWeatherVariables = this.loadWeatherVariables.bind(this);
    this.loadMarkers = this.loadMarkers.bind(this);
    this.setStations = this.setStations.bind(this);
  }

  componentDidMount() {
    setTimeout(() => { this.setState({ notInitial: true }) }, 1000);
    // if(this.state.loggedin){
    this.loadMarkers();
    this.loadWeatherVariables();
    this.loadStations();
    this.loadTabs();
    // }
    //Get users Name to display in header
    let url = BASEURI + '/api/dashboard/superuser/',
      options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };
    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        //no token exists or all tokens are expired
        if (token === false) {
          this.setState({ loggedin: false });
          return false;
        }

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => response.json())
          .then((client) => {
            this.setState({
              userName: !client.name ? client.user.username : client.name,
              isAdmin: client.user.is_superuser,
              isOrganization: client.is_organization,
              isLockedUser: client.locked
            })
          })
          .catch(error => {
            console.error('Error:', error.code);
          });
      });

  }

  componentDidUpdate() {

  }

  setStations(stations) {
    this.setState({ stations: stations });
  }


  loadStations() {
    if (this.state.notInitial) {
      this.setState({ loading: true });
    }
    let url = `${BASEURI}/api/stations/`,
      options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            return response.json();
          })
          .then((stations) => {
            if (!this.state.notInitial) {
              this.setState({ notInitial: true });
            }
            this.setState({
              loading: false,
              stations: stations,
              allStations: stations,
            });
          })
          .catch(error => console.error('Error:', error));

      });
  }

  loadMarkers() {
    this.setState({ loadingMarkers: true });
    let url = BASEURI + `/api/dashboard/marker/primary/`,
      options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        //no token exists or all tokens are expired
        if (token === false) {
          this.setState({
            loggedin: false
          });
          return false;
        }

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            return response.json();
          })
          .then((markers) => {
            if (Array.isArray(markers))
              this.setState({
                markers: markers,
                markersCopy: markers,
                loadingMarkers: false,
              })
          })
          .catch(error => {
            console.error('Error:', error.code);
            this.setState({ loadingMarkers: false });
          });
      });

  }

  loadWeatherVariables() {
    this.setState({ loadingWeatherVariables: true });
    let url = BASEURI + `/api/dashboard/weathervariable/`,
      options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        //no token exists or all tokens are expired
        if (token === false) {
          this.setState({
            loggedin: false
          });
          return false;
        }

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            return response.json();
          })
          .then((weatherVariables) => {
            if (weatherVariables)
              this.setState({
                weatherVariables: weatherVariables,
                loadingWeatherVariables: false,
              });
          })
          .catch(error => {
            console.error('Error:', error.code);
            this.setState({
              loadingWeatherVariables: false,
            })
          });
      });
  }

  handleMarkersFilterChange(event) {

    let filterText = event.filter.value.toLowerCase();
    let filtered = this.state.markersCopy.slice().filter(item => {
      let domain = `${item.device && item.device.name + ' ' + item.device.serial} ${item.location_name}`
        .toLowerCase();

      if (domain.indexOf(filterText) !== -1)
        return true;
      else
        return false;
    });

    this.setState({
      markers: filtered
    });

  }

  login(credentials) {

    this.setState({ loggingIn: true });

    const url = BASEURI + '/api/token/';
    fetch(url,
      {
        method: 'POST',
        body: JSON.stringify(credentials),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    )
      .then(response => response.json())
      .then((token) => {
        if (token.refresh) {

          localStorage.setItem('token', JSON.stringify(token));


          //what can be done here ?
          window.location.reload();
          // this.setState({loggedin:true});

          // this.forceUpdate();
        } else {
          // alert("Invalid Credentails")
          this.setState({
            loggingIn: false,
            errorMsg: <FormattedMessage
              id='app.login.error'
              defaultMessage="Invalid username or password."
              description='User login error'
            />
          })
        }


      })
      .catch(error => {
        this.setState({ loggingIn: false });
        console.error('Error:', error)
      });
    // .finally(()=>{
    //   this.forceUpdate();
    // });

  }



  loadTabs() {

    var token = localStorage.getItem('token');
    if (!token)
      this.setState({ loggedin: false });

    let url = BASEURI + '/api/tabs/',
      options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        //no token exists or all tokens are expired
        if (token === false) {
          this.setState({ loggedin: false });
          return false;
        }

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            return response.json();
          })
          .then((tabs) => {
            if (tabs) {
              var page = this.state.isLockedUser ? 'billing' : 'admin'
              this.setState({
                page: page,
                currentTab: tabs[0].id,
                currentTabName: tabs[0].name,
                tabs: tabs,
                // charts:userdata.charts,
                // sensors:userdata.sensors,
                // graphs:userdata.graphs,
                loggedin: true,
              });
            }
          })
          .catch(error => {
            console.error('Error:', error.code);
          });

      });

  }

  navigate(e, page) {
    e.preventDefault();
    this.setState({ page: page });
  }

  addTab(e) {
    e.preventDefault();

    let url = this.state.tabDialogAction === 'edit'
      ? `${BASEURI}/api/tab/rud/${this.state.newTab.id}/`
      : `${BASEURI}/api/tabs/`,
      options = {
        method: this.state.tabDialogAction === 'edit' ? 'PUT' : 'POST',
        body: JSON.stringify(this.state.newTab),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => response.json())
          .then((tab) => {
            let tabs = this.state.tabs.slice(),
              index;

            if (this.state.tabDialogAction === 'new')
              tabs.push(tab);
            else {
              for (let i in this.state.tabs)
                if (tab.id === this.state.tabs[i].id)
                  index = i;

              tabs.splice(index, 1, tab);
            }

            this.setState({
              tabs: tabs,
              currentTab: tab.id,
              currentTabName: tab.name,
            });

          })
          .catch(error => console.log('Error:', error));

      });

  }

  openTabDialog(e, action, tabId) {
    let newTab = { id: tabId };
    if (action === "edit") {
      for (let i in this.state.tabs) {
        if (tabId === this.state.tabs[i].id)
          newTab.name = this.state.tabs[i].name;
      }

    }

    this.setState({ showTabDialog: true, tabDialogAction: action, newTab: newTab });

  }

  closeTabDialog(e, tabId) {

    this.setState({ showTabDialog: false });

  }

  deleteTab() {
    // if (!window.confirm(`Are you sure you want to delete tab '${this.state.newTab.name}' and the charts in it ?`))
    //   return;

    let url = `${BASEURI}/api/tab/rud/${this.state.newTab.id}/`,
      options = {
        method: 'DELETE',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then(() => {
            let tabs = this.state.tabs.slice(),
              index;

            for (let i in this.state.tabs)
              if (this.state.newTab.id === this.state.tabs[i].id)
                index = i;

            tabs.splice(index, 1);

            this.setState({
              tabs: tabs,
              currentTab: this.state.tabs[0].id,
              showTabDialog: false
            });

          })
          .catch(error => console.log('Error:', error));

      });

  }

  makeTabCurrent(e, tabId) {
    // let tabId = 
    e.preventDefault();

    this.state.tabs.map((tab) => {
      if (tabId === tab.id) {
        this.setState({
          currentTabName: tab.name,
        });
      };
      return null;
    })
    this.setState({
      page: 'dashboard',
      currentTab: tabId
    });
  }

  handleTabFormChange(event) {
    let id = this.state.newTab.id;
    this.setState({ newTab: { name: event.target.value, id: id } });
  }

  logout(e) {

    localStorage.removeItem('token');
    this.setState({
      loggedin: localStorage.getItem('token') ? true : false
    });

    //reloading page to fix half loading
    window.location.reload()
  }

  // componentDidCatch(error, info){

  //   console.log(error);
  //   console.log(info);

  // }

  render() {

    if (this.state.loggedin) {
      var page, tabs;

      tabs = this.state.tabs.map(
        (tab, i) => {
          let active = this.state.currentTab === tab.id ? true : false;
          //why tab.id doesn't work as key?
          return (<Tab
            key={'tab' + tab.id}
            onClick={this.makeTabCurrent}
            href={'tab' + tab.id}
            isActive={active}
            id={tab.id}
            tabName={tab.name}
            isCurrentPage={this.state.page === 'dashboard' ? true : false}
          />);
        }
      );
      if (this.state.isLockedUser) {
        page = <Billing isAdmin={this.state.isAdmin} locked={this.state.isLockedUser} key='billing' />
      }
      else {
        switch (this.state.page) {
          case 'settings':
            page = <Settings key='settings' />;
            break;
          case 'profile':
            page = <Profile key='profile' />;
            break;
          case 'stations':
            page = <Stations intl={this.props.intl} key='stations'
              tabs={this.state.tabs} />;
            break;
          case 'alerts':
            page = <Alerts intl={this.props.intl} key='alerts' />;
            break;
          case 'admin':
            page = <Admin
              isAdmin={this.state.isAdmin}
              isOrganization={this.state.isOrganization}
              logout={this.logout}
              key='admin'
              markers={this.state.markers}
            />;
            break;
          case 'dga':
            page = <DGAUploadJobs key='dga' />;
            break;
          case 'copy':
            page = <Tools
              key='copy'
              stations={this.state.stations}
              allStations={this.state.allStations}
            />;
            break;
          case 'billing':
            page = <Billing isAdmin={this.state.isAdmin} key='billing' />;
            break;
          case 'schedule':
            page = <IrrigationScheduler
              key='schedule'
            />;
            break;
          case 'agscout':
            page = <ScoutPage />;
            break;

          default:
            page = this.state.tabs.map((tab, i) => {
              let active = this.state.currentTab === tab.id ? true : false;

              return <Dashboard
                intl={this.props.intl}
                //why tab.id doesn't work as key?
                setStations={this.setStations}
                loadStations={this.loadStations}
                stations={this.state.stations}
                allStations={this.state.allStations}
                handleMarkersFilterChange={this.handleMarkersFilterChange}
                loadingWeatherVariables={this.state.loadingWeatherVariables}
                weatherVariables={this.state.weatherVariables}
                loadingMarkers={this.state.loadingMarkers}
                markers={this.state.markers}
                key={'dash-' + tab.id}
                tabId={tab.id}
                tabName={tab.name}
                isActive={active}
                isCurrentPage={this.state.page === 'dashboard' ? true : false}
                charts={this.state.charts}
                addTab={this.addTab}
                handleTabFormChange={this.handleTabFormChange}
                openTabDialog={this.openTabDialog}
                closeTabDialog={this.closeTabDialog}
              />;

            });
        }
      }
    }
    // var dashs = this.state.tabs.map((tab, i) => {
    //   return (
    //     <Dashboard 
    //     //why tab.id doesn't work as key?
    //       key={'dashboard'+tab.id}
    //       tabId={tab.id}
    //       isActive={this.state.currentTab === tab.id ? true: false}
    //     />
    //   );
    // });

    return <div>
      {this.state.loggedin ?
        <LayoutCtn key='layoutctn'>
          <Layout key={'layout'}
            handleLocaleChange={this.props.handleLocaleChange}
            locale={this.props.locale}
            currentTabName={this.state.currentTabName}
            userName={this.state.userName}
            isLockedUser={this.state.isLockedUser}
            tabs={tabs}
            onClick={this.navigate}
            // changeTab={this.addTab}
            logout={this.logout}
            active={this.state.page === 'dashboard' ? true : false}
            changeTab={this.makeTabCurrent}
            tabList={this.state.tabs}
            activeTab={this.state.currentTab}
            open={this.openTabDialog}
            isAdmin={this.state.isAdmin}
            isOrganization={this.state.isOrganization}
          >
            {page}
            <Modal>
              <TabDialog
                intl={this.props.intl}
                visible={this.state.showTabDialog}
                close={this.closeTabDialog}
                create={(e) => { this.addTab(e); this.closeTabDialog() }}
                delete={this.deleteTab}
                handleChange={this.handleTabFormChange}
                tab={this.state.newTab}
                action={this.state.tabDialogAction}
                showDeleteDialog={() => this.setState({ showDeleteDialog: true })}
              />
              {
                this.state.showDeleteDialog &&
                <ConfirmDeleteDialog
                  name={this.state.newTab.name}
                  onClose={() => this.setState({ showDeleteDialog: false })}
                  remove={() => { this.deleteTab(); this.setState({ showDeleteDialog: false }) }} />
              }
            </Modal>
          </Layout>
        </LayoutCtn> :
        <LoginCtn key='loginctn'>
          <Login
            key='login'
            errorMsg={this.state.errorMsg}
            login={this.login}
            loggingIn={this.state.loggingIn} />
        </LoginCtn>
      }
    </div>;


    // return (
    //     <Layout
    //       content={page}
    //       tabs={tabs}
    //       onClick={this.navigate}
    //       logout={this.logout}
    //       addTab={this.addTab}
    //     />
    // );



  }
}

export default App;
